import { AxiosError } from "axios";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { Button, Modal, TextInput } from "../../..";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { setIsDeleteBucketModalShowing } from "../../../../store/reducers/settings";
import {
  ActionButtonsWrapper,
  DeleteBucketContainer,
  Description,
  ErrorText,
  HelpText,
} from "./ModalDeleteBucket.style";
import { deleteBucket } from "../../../../services/apis";
import { ErrorMessage } from "../../../organisms/messages/ErrorMessage";
import { useHistory } from "react-router-dom";
import {
  addToast,
  removeToast,
  ToastProps,
} from "../../../../store/reducers/toasts";
import { v4 as uuid } from "uuid";

export const ModalDeleteBucket = () => {
  const dispatch = useAppDispatch();
  const { currentSpace } = useAppSelector((state) => state.spaces);
  const history = useHistory();

  const { user } = useAppSelector((state) => state.user);
  const { bucketName } = useAppSelector((state) => state.files);
  const { isDeleteBucketModalShowing } = useAppSelector(
    (state) => state.settings
  );

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inputText, setInputText] = useState<string>("");
  const [isBucketNameValid, setIsBucketNameValid] = useState<boolean>(false);
  const [isDeletingBucket, setIsDeletingBucket] = useState<boolean>(false);

  const onCloseModal = () => {
    dispatch(setIsDeleteBucketModalShowing(false));
  };

  const onTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    setInputText(value);

    setIsBucketNameValid(value === bucketName);
  };

  const onKeydown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && isBucketNameValid) {
      onConfirmDelete();
    }
  };

  const onConfirmDelete = async () => {
    const id = uuid();
    setIsDeletingBucket(true);

    try {
      const response = await deleteBucket(currentSpace, bucketName);
      if (response.status === 204) {
        onCloseModal();

        const toastData: ToastProps = {
          id,
          status: "success",
          title: "Bucket Deleted",
          description: `Bucket "${bucketName}" has been deleted.`,
        };
        dispatch(addToast(toastData));

        let timer = setTimeout(() => {
          dispatch(removeToast(id));
          clearTimeout(timer);
        }, 5000);
        history.push(`/${user?.team.namespace}/spaces/${currentSpace}/bucket`);
      }
    } catch (error) {
      const e = error as AxiosError;
      const errorData = e.response?.data;
      const errorCode = errorData && typeof errorData === 'object' && 'errorCode' in errorData ? errorData.errorCode : '';
      setErrorMessage(errorCode as string);
      setIsDeletingBucket(false);

      const toastData: ToastProps = {
        id,
        status: "error",
        title: "Delete Bucket Failed",
        description: "Bucket couldn't be deleted. Please try again.",
      };
      dispatch(addToast(toastData));

      let timer = setTimeout(() => {
        dispatch(removeToast(id));
        clearTimeout(timer);
      }, 5000);
    }
  };

  useEffect(() => {
    return () => {
      setInputText("");
      setIsBucketNameValid(false);
      setIsDeletingBucket(false);
    };
  }, [isDeleteBucketModalShowing]);

  return (
    <Modal
      title={"Delete Bucket"}
      onClose={onCloseModal}
      isOpen={isDeleteBucketModalShowing}
    >
      <DeleteBucketContainer>
        <Description>
          Are you sure you want to delete <strong>“{bucketName}”</strong>?
        </Description>
        <HelpText>
          Confirm you want to permanently delete this bucket by entering its
          name below.
        </HelpText>
        <TextInput
          text={inputText}
          onChange={(event) => onTextChange(event)}
          onKeyDown={onKeydown}
          placeHolder="bucketName"
          testId="delete-bucket-input"
        />
        {Boolean(errorMessage) && <ErrorText>{ErrorMessage}</ErrorText>}
        <ActionButtonsWrapper>
          <Button onClick={onCloseModal} buttonStyle={"secondary-a"}>
            Cancel
          </Button>
          <Button
            type="submit"
            disable={!isBucketNameValid || isDeletingBucket}
            onClick={onConfirmDelete}
            buttonStyle={"danger"}
            data-cy="delete-bucket-submit"
          >
            Delete
          </Button>
        </ActionButtonsWrapper>
      </DeleteBucketContainer>
    </Modal>
  );
};
