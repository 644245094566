/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  MouseEvent,
  ChangeEvent,
  KeyboardEvent,
} from "react";

import { ContentContainer, LoadingContainer } from "../../../../pages/manageSpace/ManageSpace.style";
import {
  Title,
  SubTitle,
  Description,
  TableWrapper,
  TableText,
  InputWrapper,
  ModalText,
  ActionWrapper,
  DocumentationLink,
  LinkIconWrapper,
  ManageSpaceKeyContainer,
  ContentHeader,
  AccessTitle,
} from "./ManageSpaceKeys.style";

import { BaseTable, Button, InputCopy } from "../../..";
import { SecretCopy } from "../../../molecules/secretCopy/SecretCopy";
import { Popover } from "../../../atoms/popover/Popover";
import { GenerateKeysModal } from "../../../organisms/generateKeysModal/GenerateKeysModal";
import { DeleteModal } from "../../../organisms/deleteModal/DeleteModal";

import { ColumnProps } from "../../../atoms/tableHeader/TableHeader";
import { TableRowObject } from "../../../atoms/tableBody/TableBody";
import { TableActionButton } from "../../../../pages/fileList/FileList.style";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getTeamsAccessKey,
  generateTeamsAccessKey,
  deleteTeamsAccessKey,
  editTeamsAccessKeyPermission,
} from "../../../../services/apis";
import { useAppDispatch } from "../../../../store/hooks";
import {
  addToast,
  removeToast,
  ToastProps,
} from "../../../../store/reducers/toasts";
import { v4 as uuid } from "uuid";
import { RouteComponentProps } from "react-router";
import { AccessKeyDetailsModal } from "../../../organisms/accessKeyDetailsModal/AccessKeyDetailsModal";
import {
  resetLoadingState,
  setLoadingState,
} from "../../../../store/reducers/loading";
import { LoadingPlaceholder } from "../../../atoms/loadingPlaceholder/LoadingPlaceholder";
import { BaseErrorMessage } from "../../../molecules/baseErrorMessage/BaseErrorMessage";
import { ErrorMessage } from "../../../organisms/messages/ErrorMessage";
import { LoadingMessage } from "../../../organisms/messages/LoadingMessage";
import { AxiosError } from "axios";
import { validateKey } from "../../../../utils/validate";

type TeamAccessKeysProps = {
  id: string;
  accessKeyId: string;
  secretAccessKey: string;
  endpoint: string;
  permission: "read" | "write" | "readwrite" | "full";
};

interface DataProps {
  endpoint: string;
  teamAccessKeys: TeamAccessKeysProps[];
}

interface MatchParams {
  spaceKey: string;
}

interface ManageTeamAccessKeyProps extends RouteComponentProps<MatchParams> {}

export const ManageSpaceKeys = (props: ManageTeamAccessKeyProps) => {
  const spaceKey = props.match.params.spaceKey;

  const dispatch = useAppDispatch();

  const [data, setData] = useState<DataProps>();
  const [tableRows, setTableRows] = useState<Array<TableRowObject>>();
  const [tableColumns, setTableColumns] = useState<Array<ColumnProps>>();

  const [popoverIndex, setPopoverIndex] = useState<number>(-1);

  const [error, setError] = useState<string>("");
  const [pageLoading, setPageLoading] = useState<boolean>(true);

  const [isGenerateModalShowing, setIsGenerateModalShowing] = useState<boolean>(
    false
  );
  const [isEditModalShowing, setIsEditModalShowing] = useState<boolean>(false);
  const [isPermissionChange, setIsPermissionChange] = useState<boolean>(false);
  const [
    isKeyDetailsModalShowing,
    setIsKeyDetailsModalShowing,
  ] = useState<boolean>(false);

  const [targetIndex, setTargetIndex] = useState<number>(-1);
  const [isDeleteModalShowing, setIsDeleteModalShowing] = useState<boolean>(
    false
  );

  const [keyValue, setKeyValue] = useState<string>("");
  const [accessKeyId, setAccessKeyId] = useState<string>("");
  const [accessPermission, setAccessPermission] = useState<
    "read" | "write" | "readwrite" | "full"
  >("read");

  const parsePermission = (permission: "read" | "write" | "readwrite" | "full") => {
    switch (permission) {
      case "read":
        return "Read only";
      case "write":
        return "Write only";
      case "readwrite":
        return "Read/Write";
      case "full":
        return "Full Control";
      default:
        return "Read only";
    }
  };

  const getPopoverItems = (index: number) => {
    return [
      {
        title: "See Key Details",
        onClick: () => {
          if (data) {
            setTargetIndex(index);
            setKeyValue(data.teamAccessKeys[index].id);
            setAccessPermission(data.teamAccessKeys[index].permission);
            setAccessKeyId(data.teamAccessKeys[index].accessKeyId);
            setIsKeyDetailsModalShowing(true);
          }
          setPopoverIndex(-1);
        },
      },
      {
        title: "Manage Key Permission",
        onClick: () => {
          if (data) {
            setTargetIndex(index);
            setKeyValue(data.teamAccessKeys[index].id);
            setAccessPermission(data.teamAccessKeys[index].permission);
            setIsEditModalShowing(true);
          }
          setPopoverIndex(-1);
        },
      },
    ];
  };

  const getPopoverBottomAction = (index: number) => {
    return {
      title: "Delete",
      color: "#EB5757",
      onClick: () => {
        setIsDeleteModalShowing(true);
        setTargetIndex(index);
        setPopoverIndex(-1);
      },
    };
  };

  const onClickPopover = (e: MouseEvent<HTMLElement>, index: number): void => {
    /* Prevent folder browsing */
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setPopoverIndex(index);
  };

  const onDismissPopover = (e: MouseEvent<HTMLElement>): void => {
    /* Prevent folder browsing */
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    setPopoverIndex(-1);
  };

  const mapTableRows = (): void => {
    if (!data) {
      return;
    }

    const rows: TableRowObject[] = data?.teamAccessKeys.map(
      (d: TeamAccessKeysProps, index: number) => {
        return {
          redirectUrl: undefined,
          isRowActive: false,
          columns: [
            <TableText>{d.id}</TableText>,
            <TableText>{parsePermission(d.permission)}</TableText>,
            <InputCopy value={d.accessKeyId} />,
            <SecretCopy spaceKey={spaceKey} subUserId={d.id} />,
            <ActionWrapper>
              <TableActionButton
                onClick={(e: MouseEvent<HTMLElement>) =>
                  onClickPopover(e, index)
                }
              >
                <FontAwesomeIcon
                  color={"#6C757D"}
                  icon={["fas", "ellipsis-h"]}
                />
              </TableActionButton>
              <Popover
                isShowing={popoverIndex === index}
                items={getPopoverItems(index)}
                bottomAction={getPopoverBottomAction(index)}
                onDismiss={(e: MouseEvent<HTMLElement>) => onDismissPopover(e)}
              />
            </ActionWrapper>,
          ],
        };
      }
    );

    const columns: ColumnProps[] = [
      { title: "Name", size: "lg" },
      { title: "Permission", size: "md" },
      { title: "Access Key ID", size: "md" },
      { title: "Secret Access Key", size: "md" },
      { title: "", size: "xs" },
    ];

    setTableRows(rows);
    setTableColumns(columns);
  };

  const handleKeyChange = (e: ChangeEvent<HTMLInputElement>) => {
    setKeyValue(e.target.value);
  };

  const handleSelect = (selectValue: "read" | "write" | "readwrite") => {
    setAccessPermission(selectValue);
  };

  const postData = async () => {
    dispatch(setLoadingState());

    const id = uuid();
    try {
      const response = await generateTeamsAccessKey(spaceKey, {
        id: keyValue,
        access: accessPermission,
      });

      if (response.status === 200) {
        const toastData: ToastProps = {
          id,
          status: "success",
          title: "Key Generated",
          description: `Key "${keyValue}" has been generated.`,
        };
        dispatch(addToast(toastData));
        handleClosingGenerateKeyModal();
        loadData();

        let timer = setTimeout(() => {
          dispatch(removeToast(id));
          clearTimeout(timer);
        }, 5000);
      }
    } catch (error) {
      const e = error as AxiosError;
      const errorData = e.response?.data;
      const errorCode = errorData && typeof errorData === 'object' && 'errorCode' in errorData ? errorData.errorCode : '';

      const toastData: ToastProps = {
        id,
        status: "error",
        title: "Generate Key Failed",
        description: `Key couldn't be generated due to ${errorCode}. Please try again.`,
      };
      dispatch(addToast(toastData));

      let timer = setTimeout(() => {
        dispatch(removeToast(id));
        clearTimeout(timer);
      }, 5000);
    }
    dispatch(resetLoadingState());
  };

  const handleClosingGenerateKeyModal = () => {
    setIsGenerateModalShowing(false);
    setKeyValue("");
    setAccessPermission("read");
  };

  const handleGenerateKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && validateKey(keyValue)) {
      postData();
    } else if (e.key === "Escape") {
      handleClosingGenerateKeyModal();
    }
  };

  const handleGenerateKeySubmit = () => {
    postData();
  };

  const handleEditSelected = (selectValue: "read" | "write" | "readwrite" | "full") => {
    setAccessPermission(selectValue);
    selectValue !== data?.teamAccessKeys[targetIndex].permission
      ? setIsPermissionChange(true)
      : setIsPermissionChange(false);
  };

  const handleClosingEditKeyModal = () => {
    setIsEditModalShowing(false);
    setKeyValue("");
    setAccessPermission("read");
    setIsPermissionChange(false);
    setTargetIndex(-1);
  };

  const handleEditKeySubmit = () => {
    dispatch(setLoadingState());

    const putData = async () => {
      const id = uuid();
      try {
        const response = await editTeamsAccessKeyPermission(spaceKey, {
          id: keyValue,
          access: accessPermission,
        });

        if (response.status === 200) {
          const toastData: ToastProps = {
            id,
            status: "success",
            title: "Key Permissions Updated",
            description: "Space key permissions have been updated.",
          };
          dispatch(addToast(toastData));
          handleClosingEditKeyModal();
          loadData();

          let timer = setTimeout(() => {
            dispatch(removeToast(id));
            clearTimeout(timer);
          }, 5000);
        }
      } catch (error) {
        const e = error as AxiosError;
        const errorData = e.response?.data;
        const errorCode = errorData && typeof errorData === 'object' && 'errorCode' in errorData ? errorData.errorCode : '';

        const toastData: ToastProps = {
          id,
          status: "error",
          title: "Update Key Permissions Failed",
          description: `Key permissions couldn't be updated due to ${errorCode}. Please try again.`,
        };
        dispatch(addToast(toastData));

        let timer = setTimeout(() => {
          dispatch(removeToast(id));
          clearTimeout(timer);
        }, 5000);
      }
      dispatch(resetLoadingState());
    };

    putData();
  };

  const handleDeleteKey = () => {
    dispatch(setLoadingState());
    const deleteData = async () => {
      if (data?.teamAccessKeys[targetIndex].id) {
        const id = uuid();
        try {
          const response = await deleteTeamsAccessKey(
            spaceKey,
            data.teamAccessKeys[targetIndex].id
          );

          if (response.status === 204) {
            const toastData: ToastProps = {
              id,
              status: "success",
              title: "Key Deleted",
              description: `Key "${data.teamAccessKeys[targetIndex].id}" has been deleted.`,
            };
            dispatch(addToast(toastData));
            setTargetIndex(-1);
            setIsDeleteModalShowing(false);
            loadData();

            let timer = setTimeout(() => {
              dispatch(removeToast(id));
              clearTimeout(timer);
            }, 5000);
          }
        } catch (error) {
          const e = error as AxiosError;
          const errorData = e.response?.data;
          const errorCode = errorData && typeof errorData === 'object' && 'errorCode' in errorData ? errorData.errorCode : '';
          const toastData: ToastProps = {
            id,
            status: "error",
            title: "Delete Key Failed",
            description: `Key couldn't be deleted due to ${errorCode}. Please try again.`,
          };
          dispatch(addToast(toastData));

          let timer = setTimeout(() => {
            dispatch(removeToast(id));
            clearTimeout(timer);
          }, 5000);
        }
      }
      dispatch(resetLoadingState());
    };

    deleteData();
  };

  const loadData = async () => {
    setError("");
    try {
      const response = await getTeamsAccessKey(spaceKey);
      const data = response.data;
      const accessKeys = data.teamAccessKeys.map(
        (item: {
          permission: "read" | "write" | "readwrite" | "read-write" | "full" | "full-control";
        }) => {
          if (item.permission === "read-write") {
            return { ...item, permission: "readwrite" };
          }
          if (item.permission === "full-control") {
            return { ...item, permission: "full" };
          }
          return item;
        }
      );
      const newData = { endpoint: data.endpoint, teamAccessKeys: accessKeys };
      setData(newData);
    } catch (error) {
      const e = error as AxiosError;
      const errorData = e.response?.data;
      const errorCode = errorData && typeof errorData === 'object' && 'errorCode' in errorData ? errorData.errorCode : '';
      setError(errorCode as string);
    }
    setPageLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    mapTableRows();
  }, [data, popoverIndex]);

  // Remove scroll when open modal.
  useEffect(() => {
    if (isGenerateModalShowing || isEditModalShowing) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isGenerateModalShowing, isEditModalShowing]);

  if (pageLoading) {
    return (
      <LoadingContainer>
        <LoadingMessage />
      </LoadingContainer>
    );
  } else if (error !== "") {
    return <ErrorMessage />;
  }

  return (
    <ManageSpaceKeyContainer>
      <ContentContainer>
        <Title>Space Access Keys</Title>
        <Description>
          By using access keys, you can connect to your storage or use Amazon
          S3-compatible software to manage the files/buckets.
          <DocumentationLink
            href="https://docs.byteark.com/th/storage/access-key-management.html"
            target={"_blank"}
          >
            Learn More
            <LinkIconWrapper>
              <FontAwesomeIcon size={"sm"} icon={["far", "external-link"]} />
            </LinkIconWrapper>
          </DocumentationLink>
        </Description>
        <SubTitle>Endpoint</SubTitle>
        <InputWrapper>
          {data ? (
            <InputCopy value={data.endpoint} />
          ) : (
            <LoadingPlaceholder width={"100%"} height={33} />
          )}
        </InputWrapper>
        <ContentHeader>
          <AccessTitle>Access Keys</AccessTitle>
          {Boolean(!tableColumns || tableRows?.length) && (
            <Button
              buttonStyle="secondary-a"
              isIconButton
              onClick={() => setIsGenerateModalShowing(true)}
            >
              <FontAwesomeIcon icon={["fas", "plus"]} />
              Generate New Key
            </Button>
          )}
        </ContentHeader>
        {tableColumns && tableRows && (
          <TableWrapper>
            <BaseTable columns={tableColumns} rows={tableRows} />
          </TableWrapper>
        )}
        {(!tableColumns || !tableRows?.length) && (
          <BaseErrorMessage
            title={"This Space doesn't have any access key"}
            description={
              "You can generate a new access key to sign programmatic requests to ByteArk Storage service."
            }
            icon={"key"}
            button={{
              text: (
                <>
                  <FontAwesomeIcon icon={["fas", "plus"]} /> Generate New Key
                </>
              ),
              onClick: () => setIsGenerateModalShowing(true),
            }}
          />
        )}
      </ContentContainer>
      {/* Generate Modal */}
      <GenerateKeysModal
        keyValue={keyValue}
        isOpen={isGenerateModalShowing}
        onKeyChange={handleKeyChange}
        onSelect={handleSelect}
        onClose={handleClosingGenerateKeyModal}
        onSubmit={handleGenerateKeySubmit}
        onKeyDown={handleGenerateKeyDown}
      />
      {/* Edit Modal */}
      <GenerateKeysModal
        keyValue={keyValue}
        isOpen={isEditModalShowing}
        currentPermission={accessPermission}
        onKeyChange={(e) => {}}
        onSelect={handleEditSelected}
        onClose={handleClosingEditKeyModal}
        onSubmit={handleEditKeySubmit}
        isChanged={isPermissionChange}
        isManagePermissionModal
      />
      {/* Key Details Modal */}
      <AccessKeyDetailsModal
        isOpen={isKeyDetailsModalShowing}
        keyValue={keyValue}
        permission={parsePermission(accessPermission)}
        accessKeyID={accessKeyId}
        onClose={() => setIsKeyDetailsModalShowing(false)}
        spaceKey={spaceKey}
      />
      {data && targetIndex >= 0 && (
        <DeleteModal
          title="Delete Key"
          description={`Any API call made using this key will fail. Before you delete this key, make sure that it’s no longer in use. You cannot recover after you delete it.`}
          isOpen={isDeleteModalShowing}
          onClose={() => setIsDeleteModalShowing(false)}
          onDelete={handleDeleteKey}
        >
          <ModalText>
            Are you sure you want to delete{" "}
            <strong>"{data.teamAccessKeys[targetIndex].id}"</strong>?
          </ModalText>
        </DeleteModal>
      )}
    </ManageSpaceKeyContainer>
  );
};
