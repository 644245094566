/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "../../components/GlobalStyle/GlobalStyle.style";
import {
  PageContainer,
  Content,
  ButtonContainer,
  FlexContainer,
  SearchBoxWrapper,
} from "./SpaceList.style";
import { SpaceListHeader } from "../../components/organisms/spaceList/spaceListHeader/SpaceListHeader";
import { Button } from "../../components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SearchBox } from "../../components/atoms/searchBox/SearchBox";
import { SectionSpaceCards } from "../../components/organisms/spaceList/sectionSpaceCards/SectionSpaceCards";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  setFilteredSpaces,
  setIsSpaceCreationShowing,
  setSpaceSearchValue,
} from "../../store/reducers/spaces";
import { ChangeEvent, useEffect } from "react";
import _ from "lodash";
import { EmptyMessage } from "../../components/organisms/messages/EmptyMessage";
import { SearchResultContainer } from "../bucketList/BucketList.style";
import { NoResultMessage } from "../../components/organisms/messages/NoResultMessage";
import { PopupUploadProgress } from "../../components/organisms/fileList/popupUploadProgress/PopupUploadProgress";

export const SpaceList = () => {
  const {
    spaces,
    filteredSpaces,
    spaceSearchValue,
    isSpaceLoading,
    error,
  } = useAppSelector((state) => state.spaces);

  const dispatch = useAppDispatch();

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSpaceSearchValue(event.target.value));
    if (event.target.value === "") {
      dispatch(setFilteredSpaces(spaces));
    } else {
      const filtered = _.filter(spaces, (item) => {
        return item.key
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      });
      dispatch(setFilteredSpaces(filtered));
    }
  };

  const onDeleteText = () => {
    dispatch(setSpaceSearchValue(""));
    dispatch(setFilteredSpaces(spaces));
  };

  useEffect(() => {
    document.title = "ByteArk Storage";
  }, []);

  return (
    <PageContainer>
      <SpaceListHeader />
      <Container>
        <Content>
          <FlexContainer>
            <SearchBoxWrapper>
              <SearchBox
                text={spaceSearchValue}
                placeholder="Search by Space name..."
                onChange={handleSearchChange}
                onDelete={onDeleteText}
              />
            </SearchBoxWrapper>
            <ButtonContainer>
              <Button
                isIconButton
                fullWidth
                onClick={() => dispatch(setIsSpaceCreationShowing(true))}
                disable={Boolean(error)}
                data-cy="new-space-button"
              >
                <FontAwesomeIcon icon={["fas", "plus"]} />
                New Space
              </Button>
            </ButtonContainer>
          </FlexContainer>
          <SectionSpaceCards />
          {!isSpaceLoading &&
            spaceSearchValue.length > 0 &&
            filteredSpaces.length === 0 && (
              <>
                <SearchResultContainer>
                  {filteredSpaces.length !== 0 ? filteredSpaces.length : "No"}{" "}
                  results for ‘{spaceSearchValue}’
                </SearchResultContainer>
                <NoResultMessage
                  description={`We couldn’t find any spaces matching '${spaceSearchValue}'`}
                />
              </>
            )}
          {!isSpaceLoading && !error &&
            spaceSearchValue.length === 0 &&
            filteredSpaces.length === 0 && (
              <EmptyMessage
                description={
                  "Let's get started by creating a new space to store your buckets."
                }
                item={"spaces"}
                button={{
                  text: (
                    <>
                      <FontAwesomeIcon icon={["fas", "plus"]} /> New Space
                    </>
                  ),
                  onClick: () => dispatch(setIsSpaceCreationShowing(true)),
                }}
              />
            )}
        </Content>
      </Container>
      <PopupUploadProgress />
    </PageContainer>
  );
};
