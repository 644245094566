import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import Router from "./router";
import GlobalStyle from "./components/GlobalStyle/GlobalStyle";
import { store } from "./store";
import { StackToast } from "./components/molecules/stackToast/StackToast";

ReactDOM.render(
  <GlobalStyle>
    <Provider store={store}>
      <Router />
      <StackToast />
    </Provider>
  </GlobalStyle>,
  document.getElementById("root")
);
