import styled from "styled-components";
import { SelectButton } from "../../../atoms/select/Select.style";
import { maxLayout } from "../../../../utils/breakpoint/breakpoint";

export const PermissionSettings = styled.div`
  margin-top: var(--space-base);
  margin-bottom: var(--space-s);
`;

export const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  
  @media ${maxLayout.sm} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const PermissionText = styled.div`
  flex: 0 0 62%;

  @media ${maxLayout.sm} {
    flex: 0 0 100%;
    margin-bottom: var(--space-s);
  }
`;

export const PermissionDropdown = styled.div`
  flex: 0 0 35%;
  
  ${SelectButton} {
    background-color: var(--grey200);
  }

  @media ${maxLayout.sm} {
    flex: 0 0 100%;
  }
`;

export const PermissionTitle = styled.p`
  color: var(--grey900);
  font-size: 14px;
  font-weight: 700;
`;

export const PermissionDescription = styled.p`
  color: var(--grey700);
  font-size: 12px;
`;

export const PermissionWarning = styled.div<{ hide: boolean }>`
  margin-top: 10px;
  font-size: 12px;
  color: var(--red);

  visibility: ${(props) => props.hide ? "hidden" : "visible"};

  svg, strong {
    margin-right: var(--space-xs);
  }
`;
