import styled from "styled-components";
import { TableCellStyles } from "../../molecules/baseTable/BaseTable.style";

export const TableHeaderContainer = styled.div<{ roundedBorder: boolean }>`
  position: relative;
  background-color: var(--grey200);
  border-bottom: 1.5px solid var(--grey400);
  border-bottom-left-radius: ${(props) => props.roundedBorder ? 'var(--border-radius)' : '0'};
  border-bottom-right-radius: ${(props) => props.roundedBorder ? 'var(--border-radius)' : '0'};
  
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const TableHeaderCell = styled.div`
  ${TableCellStyles};
  border-radius: 0;
  color: var(--grey900);
  font-weight: 500;
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
`;
