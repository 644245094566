import {
  Container,
  SecretCopyContainer,
} from "./SecretCopy.style";
import { InputCopy } from "../inputCopy/InputCopy";

import { getTeamsAccessSecret } from "../../../services/apis";

interface SecretCopyProps {
  value?: string;
  spaceKey: string;
  subUserId: string;
}

export const SecretCopy = (props: SecretCopyProps) => {
  const { value, spaceKey, subUserId } = props;

  const onCopySecretKey = async () => {
    try {
      const response = await getTeamsAccessSecret(spaceKey, subUserId);
      const { secretAccessKey } = response.data;
      const hiddenElement = document.createElement("input");

      document.body.appendChild(hiddenElement);
      hiddenElement.value = secretAccessKey;
      hiddenElement.select();
      document.execCommand("copy");
      document.body.removeChild(hiddenElement);
    } catch (error) {
      console.error(error);
    }
  };

  const onCopy = () => {
    const hiddenElement = document.createElement("input");

    document.body.appendChild(hiddenElement);
    hiddenElement.value = value ? value : "";
    hiddenElement.select();
    document.execCommand("copy");
    document.body.removeChild(hiddenElement);
  };

  return (
    <Container>
      <SecretCopyContainer>
        <InputCopy
          value={value ? value : "****************************************"}
          onClick={value ? onCopy : onCopySecretKey}
        />
      </SecretCopyContainer>
    </Container>
  );
};
