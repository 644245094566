import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  appendCancelTokenSourceList,
  appendFailedList, decreaseCancelCount, decreaseUploadErrorCount,
  increaseCancelCount,
  increaseUploadErrorCount,
  increaseUploadSuccessCount,
  updateUploadStatus,
  UploadStatusProps,
} from "../../../../store/reducers/upload";
import {
  CircleCancel,
  CircleCompleted,
  CircleError,
  CircleRetry,
  CircularContainer,
  ErrorMessage,
  FileProgressContainer,
  FileTitleContainer,
  UploadCanceledText,
  UploadProgressDetails,
  UploadProgressItemContainer,
} from "./UploadProgressItem.style";
import { TextWithIcon } from "../../../molecules/textWithIcon/TextWithIcon";
import { getFileIcon } from "../../../../utils/file";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios, { AxiosError, CancelTokenSource } from "axios";
import {
  cancelFileUpload,
  CancelFileUploadFilters,
  createObject,
} from "../../../../services/apis";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  parseFile,
  uploadFirstPart,
  uploadOtherParts,
} from "../../../../utils/upload";

interface UploadProgressItemProps {
  uploadStatus: UploadStatusProps;
}

export const UploadProgressItem = (props: UploadProgressItemProps) => {
  const { uploadStatus } = props;
  const {
    cancelTokenSource,
    cancelTokenSourceList,
    errorMessage,
    file,
    status,
    uploadPath,
    uploadProgress,
  } = uploadStatus;

  const { bucketName } = useAppSelector((state) => state.files);
  const { errorCount } = useAppSelector((state) => state.upload);
  const { currentSpace } = useAppSelector((state) => state.spaces);
  const dispatch = useAppDispatch();

  const extension = file.name.split(".").pop();

  const onCancelUpload = () => {
    if (!cancelTokenSource) {
      return;
    }

    if (cancelTokenSourceList) {
      // Cancel multiple parts
      cancelTokenSourceList.forEach((cts: CancelTokenSource) => {
        cts.cancel();
      });
    } else {
      // Cancel a single part
      cancelTokenSource.cancel();
    }
  };

  const onError = (error: Error, file: File) => {
    let errorMessage = "Failed to connect to the server";
    if (error.message && error.message.includes("403")) {
      errorMessage = "No Permission"
    }

    dispatch(increaseUploadErrorCount());
    dispatch(
      updateUploadStatus({
        filename: file.name,
        payload: {
          status: "error",
          errorMessage,
        },
      })
    );
  };

  const onRetryUpload = async () => {
    if (errorMessage) {
      dispatch(decreaseUploadErrorCount());
    } else {
      dispatch(decreaseCancelCount());
    }

    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = async () => {
      const binaryStr = reader.result;

      const payload = {
        spaceKey: currentSpace,
        bucketName,
        file: binaryStr,
      };

      const uploadInfo: UploadStatusProps = {
        ...uploadStatus,
        errorMessage: "",
        cancelTokenSource: null,
        status: "uploading",
        uploadPath,
        uploadProgress: 0,
      };

      dispatch(
        updateUploadStatus({ filename: file.name, payload: uploadInfo })
      );

      try {
        const ourRequest = axios.CancelToken.source();
        const axiosConfig = {
          cancelToken: ourRequest.token,
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const uploadProgress =
              (progressEvent.loaded / progressEvent.total) * 100;
            dispatch(
              updateUploadStatus({
                filename: file.name,
                payload: { uploadProgress },
              })
            );
          },
        };

        dispatch(
          updateUploadStatus({
            filename: file.name,
            payload: { cancelTokenSource: ourRequest },
          })
        );
        const response = await createObject(
          payload,
          { path: uploadPath },
          axiosConfig
        );

        if (response.status === 201) {
          dispatch(increaseUploadSuccessCount());
          dispatch(
            updateUploadStatus({
              filename: file.name,
              payload: { status: "success" },
            })
          );
        }
      } catch (error) {
        dispatch(appendFailedList(file));

        if (axios.isCancel(error)) {
          dispatch(increaseCancelCount());
          dispatch(
            updateUploadStatus({
              filename: file.name,
              payload: { status: "cancel" },
            })
          );
        } else {
          const parsedError = error as AxiosError;
          const errorData = parsedError.response?.data;
          const errorCode = errorData && typeof errorData === 'object' && 'errorCode' in errorData ? errorData.errorCode : '';
          dispatch(increaseUploadErrorCount());
          dispatch(
            updateUploadStatus({
              filename: file.name,
              payload: {
                status: "error",
                errorMessage: errorCode as string,
              },
            })
          );
        }
      }
    };

    const maxFileSize = 5 * 1024 * 1024;

    if (file.size > maxFileSize) {
      const total = Math.round(file.size / maxFileSize);
      // let chunks: Array<ArrayBuffer | string> = [];
      let finished = 0;
      let uploadInfo: UploadStatusProps = {
        ...uploadStatus,
        errorMessage: "",
        cancelTokenSource: null,
        currentChunk: 0,
        status: "uploading",
        uploadPath,
        uploadProgress: 0,
      };

      parseFile(file, {
        binary: true,
        chunk_size: 5 * 1024 * 1024,
        chunk_read_callback: async (chunk, partNo) => {
          try {
            if (partNo === 1) {
              dispatch(
                updateUploadStatus({ filename: file.name, payload: uploadInfo })
              );
              // First Chunk
              await uploadFirstPart({
                spaceKey: currentSpace,
                bucketName,
                chunk,
                fileType: file.type,
                uploadPath,
                onStart: (cancelTokenSource) => {
                  dispatch(
                    updateUploadStatus({
                      filename: file.name,
                      payload: { cancelTokenSource },
                    })
                  );
                },
                updateUploadStatus: (status) => {
                  finished += 1;
                  const uploadProgress = (finished / total) * 100;
                  uploadInfo = {
                    ...uploadInfo,
                    ...status,
                    uploadProgress,
                  };
                  dispatch(
                    updateUploadStatus({
                      filename: file.name,
                      payload: uploadInfo,
                    })
                  );
                },
              });
            } else {
              // Middle Chunks
              const handleMiddleUpload = async () => {
                if (!uploadInfo || !uploadInfo.uploadId) {
                  const timer = setTimeout(() => {
                    handleMiddleUpload();
                    clearTimeout(timer);
                  }, 2500);
                } else {
                  await uploadOtherParts({
                    spaceKey: currentSpace,
                    bucketName,
                    chunk,
                    currentChunk: partNo,
                    fileType: file.type,
                    isFinalChunk: 0,
                    uploadInfo,
                    onStart: (cancelTokenSource) => {
                      dispatch(
                        appendCancelTokenSourceList({
                          filename: file.name,
                          payload: cancelTokenSource,
                        })
                      );
                    },
                    updateUploadStatus: () => {
                      finished += 1;
                      const uploadProgress = (finished / total) * 100;
                      dispatch(
                        updateUploadStatus({
                          filename: file.name,
                          payload: { uploadProgress },
                        })
                      );
                    },
                  });
                }
              };
              await handleMiddleUpload();
            }
          } catch (error) {
            dispatch(appendFailedList(file));

            if (axios.isCancel(error)) {
              uploadInfo.status = "cancel";
              dispatch(increaseCancelCount());
              dispatch(
                updateUploadStatus({
                  filename: file.name,
                  payload: { status: "cancel" },
                })
              );
            } else {
              if (uploadInfo.status !== "cancel") {
                onError(error as AxiosError, file);
                dispatch(increaseUploadErrorCount());
              }
            }

            if (uploadInfo.uploadId) {
              const cancelFilter: CancelFileUploadFilters = {
                path: uploadPath,
              };
              await cancelFileUpload(
                currentSpace,
                bucketName,
                uploadInfo.uploadId,
                cancelFilter
              );
            }

            return; // Stop the loop if error
          }
        },
        success: async (chunk, partNo) => {
          if (errorCount) {
            return;
          }

          const handleLastUpload = async () => {
            if (finished + 1 < total) {
              const timer = setTimeout(() => {
                handleLastUpload();
                clearTimeout(timer);
              }, 4500);
            } else {
              try {
                // Last Chunk
                await uploadOtherParts({
                  spaceKey: currentSpace,
                  bucketName,
                  chunk,
                  currentChunk: partNo,
                  fileType: file.type,
                  isFinalChunk: 1,
                  uploadInfo,
                  onStart: (cancelTokenSource) => {
                    dispatch(
                      appendCancelTokenSourceList({
                        filename: file.name,
                        payload: cancelTokenSource,
                      })
                    );
                  },
                  updateUploadStatus: () => {
                    dispatch(increaseUploadSuccessCount());
                    dispatch(
                      updateUploadStatus({
                        filename: file.name,
                        payload: {
                          status: "success",
                          uploadProgress: 100,
                        },
                      })
                    );
                  },
                });
              } catch (error) {
                dispatch(appendFailedList(file));

                if (axios.isCancel(error)) {
                  dispatch(increaseCancelCount());
                  dispatch(
                    updateUploadStatus({
                      filename: file.name,
                      payload: { status: "cancel" },
                    })
                  );
                } else {
                  onError(error as AxiosError, file);
                  dispatch(increaseUploadErrorCount());
                }

                if (uploadInfo.uploadId) {
                  const cancelFilter: CancelFileUploadFilters = {
                    path: uploadPath,
                  };
                  await cancelFileUpload(
                    currentSpace,
                    bucketName,
                    uploadInfo.uploadId,
                    cancelFilter
                  );
                }

                return; // Stop the loop if error
              }
            }
          };
          await handleLastUpload();
        },
        error_callback: (error) => {
          onError(error as Error, file);
          return;
        },
      });
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <UploadProgressItemContainer>
      <UploadProgressDetails>
        <FileTitleContainer>
          <TextWithIcon
            icon={getFileIcon(extension as string)}
            text={file.name}
          >
            <>
              {Boolean(errorMessage) && (
                <ErrorMessage>{errorMessage}</ErrorMessage>
              )}
            </>
          </TextWithIcon>
        </FileTitleContainer>
        <FileProgressContainer>
          {Boolean(status === "uploading") && (
            <CircularContainer>
              <CircularProgressbar strokeWidth={16} value={uploadProgress} />
              <CircleCancel onClick={onCancelUpload}>
                <FontAwesomeIcon color={"#FFF"} icon={["fas", "times"]} />
              </CircleCancel>
            </CircularContainer>
          )}
          {Boolean(status === "cancel") && (
            <>
              <UploadCanceledText>Upload Canceled</UploadCanceledText>
              <CircleRetry onClick={onRetryUpload}>
                <FontAwesomeIcon color={"#6C757D"} icon={["fas", "undo-alt"]} />
              </CircleRetry>
            </>
          )}
          {Boolean(status === "success") && (
            <CircleCompleted>
              <FontAwesomeIcon color={"#FFF"} icon={["fas", "check"]} />
            </CircleCompleted>
          )}
          {Boolean(status === "error") && (
            <CircularContainer>
              <CircleError>
                <FontAwesomeIcon color={"#FFF"} icon={["fas", "exclamation"]} />
              </CircleError>
              <CircleRetry onClick={onRetryUpload}>
                <FontAwesomeIcon color={"#6C757D"} icon={["fas", "undo-alt"]} />
              </CircleRetry>
            </CircularContainer>
          )}
        </FileProgressContainer>
      </UploadProgressDetails>
    </UploadProgressItemContainer>
  );
};
