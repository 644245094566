import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import theme from "../../../theme/theme";

import { BreadcrumbContainer, Path, PathSpan } from "./BreadcrumbHeader.style";

export interface BreadcrumbHeaderItemObject {
  title: string;
  redirectUrl: string;
}

interface BreadcrumbHeaderProps {
  spaceKey?: string;
  bucketName?: string;
  path?: Array<BreadcrumbHeaderItemObject>;
}

export const BreadcrumbHeader = (props: BreadcrumbHeaderProps) => {
  const { path } = props;

  const iconColor = theme.colors.grey400;

  return (
    <BreadcrumbContainer>
      <Path isActive={false}>
        <Link to={"/"}>
          <FontAwesomeIcon color={iconColor} icon={["fas", "home"]} />
        </Link>
        <FontAwesomeIcon color={iconColor} icon={["far", "chevron-right"]} />
      </Path>

      {path &&
        path.map((item, index) => (
          <Path
            isActive={Boolean(path.length - 1 === index)}
            key={`breadcrumb-header-${index}`}
          >
            {path.length - 1 !== index ? (
              <Link to={item.redirectUrl}>
                <PathSpan isActive={Boolean(path.length - 1 === index)}>
                  {item.title}
                </PathSpan>
              </Link>
            ) : (
              <PathSpan isActive>{item.title}</PathSpan>
            )}
            {path.length - 1 !== index && (
              <FontAwesomeIcon
                color={iconColor}
                icon={["far", "chevron-right"]}
              />
            )}
          </Path>
        ))}
    </BreadcrumbContainer>
  );
};
