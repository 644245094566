import { css } from "styled-components";

const Colors = css`
  --primary: #2377CD;
  --secondary: #5613b5;
  --secondary2: #6957c5;

  --middle: #2377CD;
  --primaryLighten: #00A6ED;
  --secondaryLighten: #735de5;
  --primaryDarken: #006692;
  --secondaryDarken: #443880;
  --primaryOverlay: rgba(0, 166, 237, 0.1);

  --grey100: #F6F7F8;
  --grey200: #EBEDEF;
  --grey300: #D5D8DD;
  --grey400: #B1B6BE;
  --grey500: #838C95;
  --grey600: #67707B;
  --grey700: #535D69;
  --grey800: #2E3946;
  --grey900: #202831;

  --white: #ffffff;
  --overlay: #253742;
  --overlayHover: #465562;

  --navy: #212d37;
  --navyDarken: #1a242c;
  --lightBlue: #f0f5f8;

  --red: #E14242;
  --redDarken: #c33232;
  --green: #1C9F58;
  --yellow: #E6A320;

  --ci-gradient-linear: linear-gradient(188.7deg, #2EA2E5 6.63%, #6957C5 93.37%);
  --ci-gradient-45: linear-gradient(143.7deg, #00A6ED 0%, #6957C5 100%);
  --ci-gradient-45-darken: linear-gradient(143.7deg, #006692 0%, #443880 100%);
  --ci-gradient-45-lighten: linear-gradient(143.7deg, #62BFF4 21.35%, #735DE5 100%);
  --ci-gradient-45-lighten-overlay: linear-gradient(115.24deg, rgba(196, 227, 240, 0.6) 0%, rgba(149, 135, 231, 0.5) 100%);
`;

export default Colors;
