import styled, { css, keyframes } from "styled-components";

export const PopoverAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const PopoverContainer = styled.div<{ position: "bottom" | "top", isEmptyMenu: boolean }>`
  position: absolute;
  animation: ${PopoverAnimation} 250ms ease;
  width: 200px;
  border-radius: var(--border-radius-large);
  background-color: var(--white);
  box-shadow: 0 2px 9px 6px rgba(0, 0, 0, 0.05);
  z-index: 3;
  right: -83px;

  ${(props) => props.position === "bottom"? css`
    top: 42px;
  ` : css`
    bottom: 50px;
  `};

  ${(props) => props.isEmptyMenu && css`
    ${MenuSeparator} {
      display: none;
    }
    ${PopoverItemsWrapper} {
      padding-top: 0;
    }
    button {
      border-radius: var(--border-radius-large);
    }
  `};

  @media screen and (max-width: 1260px) {
    right: -15px;
  }
`;

export const PopoverArrow = styled.div<{ position: "bottom" | "top"}>`
  position: absolute;
  left: 0;
  transform: translate3d(89px, 0px, 0px);
  display: block;
  width: 32px;
  height: 21px;
  z-index: 3;

  ${(props) => props.position === "bottom"? css`
    top: -12px;
  ` : css`
    bottom: -19px;
  `};

  @media screen and (max-width: 1260px) {
    left: unset;
    right: 10px;
    transform: unset;
  }

  :before {
    position: absolute;
    display: block;
    content: "";
    border-style: solid;
    border-color: transparent;
    border-bottom-color: rgba(7, 9, 10, 0.05);
    top: 0;

    ${(props) => props.position === "bottom"? css`
      border-width: 0 12px 12px;
    ` : css`
      border-width: 12px 12px 0;
    `};
  }

  :after {
    position: absolute;
    display: block;
    content: "";
    border-style: solid;
    border-color: transparent;
    top: 1px;

    ${(props) => props.position === "bottom"? css`
      border-width: 0 12px 12px;
      border-bottom-color: var(--white);
    ` : css`
      border-width: 12px 12px 0;
      border-top-color: var(--white);
    `};
  }
`;

export const PopoverItemsWrapper = styled.div<{withBottomAction: boolean}>`
  position: relative;
  width: 100%;
  padding-top: var(--space-s);
  padding-bottom: ${(props) => props.withBottomAction ? '0' : 'var(--space-s)'};
  z-index: 3;
`;

export const PopoverItem = styled.button<{ color?: string }>`
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: pointer;
  color: ${(props) => props.color ? props.color : "var(--grey900)"};

  width: 100%;
  padding: var(--space-s);
  transition: background-color 250ms ease;

  :hover {
    background-color: var(--grey200);
  }
`;

export const MenuSeparator = styled.div`
  width: 100%;
  height: 1px;
  margin-top: var(--space-xs);
  background-color: var(--grey400);
`;

export const PopoverOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: default;
`;
