const theme = {
  colors: {
    primary: "#2377CD",
    secondary: "#5613B5",
    secondary2: "#6957C5",
    middle: "#2D83DB",
    primaryLighten: "#00A6ED",
    secondaryLighten: "#735DE5",
    primaryDarken: "#006692",
    secondaryDarken: "#443880",
    primaryOverlay: "rgba(0, 166, 237, 0.1)",
    grey100: "#F6F7F8",
    grey200: "#EBEDEF",
    grey300: "#D5D8DD",
    grey400: "#B1B6BE",
    grey500: "#838C95",
    grey600: "#67707B",
    grey700: "#535D69",
    grey800: "#2E3946",
    grey900: "#202831",
    white: "#FFFFFF",
    overlay: "#253742",
    overlayHover: "#465562",
    navy: "#212D37",
    navyDarken: "#1A242C",
    lightBlue: "#F0F5F8",
    red: "#EB5757",
    green: "#3FB772",
    yellow: "#F0B542",
  },
  spaces: {
    xl: "48px",
    l: "32px",
    m: "24px",
    base: "16px",
    s: "8px",
    xs: "4px",
  },
  borderRadius: "5px",
};

export default theme;
