import {
  Container,
  BorderLeft,
  IconWrapper,
  ContentContainer,
  TitleContainer,
  Title,
  Desciption,
} from "./Toast.style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-common-types";
import { ReactChild } from "react";

export interface ToastProps {
  /**
   * Title of this toast
   */
  title: string;
  /**
   * Description of this toast
   */
  description?: string | ReactChild;
  /**
   * Icon color
   * check-circle icon with success iconColor for success style.
   * times-circle icon with danger iconColor for unsuccess style.
   * @default success
   */
  iconColor?: "success" | "warning" | "danger";
  /**
   * Icon color
   * check-circle icon with success iconColor for success style.
   * times-circle icon with danger iconColor for unsuccess style.
   * @default check-circle
   */
  icon?: IconName;
  /**
   * Function that triggle while click this toast.
   */
  onClick?: () => void;
  /**
   * CSS Position if needed to alter.
   * @default fixed
   */
  position?: "fixed" | "relative";
  borderColor?: "success" | "warning" | "danger";
}

export const Toast = (props: ToastProps) => {
  const {
    title,
    description,
    iconColor = "success",
    icon = "check-circle",
    position = "fixed",
    borderColor = "success",
    onClick,
  } = props;
  return (
    <Container onClick={onClick} position={position}>
      <BorderLeft borderColor={borderColor}>
        <IconWrapper iconColor={iconColor}>
          {/* @ts-expect-error incompatible icon */}
          <FontAwesomeIcon icon={["fas", icon]} />
        </IconWrapper>
        <ContentContainer>
          <TitleContainer>
            <Title>{title}</Title>
            <FontAwesomeIcon
              size={"1x"}
              color={"#6c757d"}
              icon={["fas", "times"]}
            />
          </TitleContainer>
          {description && <Desciption>{description}</Desciption>}
        </ContentContainer>
      </BorderLeft>
    </Container>
  );
};
