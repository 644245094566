/* eslint-disable react-hooks/exhaustive-deps */
import axios, { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  ActionButton,
  DropArea,
  TableContainer,
  UploadButtonContainer,
  UploadModalContainer,
} from "./ModalUpload.style";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  appendCancelTokenSourceList,
  appendFailedList,
  appendFileList,
  appendUploadStatus,
  increaseCancelCount,
  increaseUploadErrorCount,
  increaseUploadSuccessCount,
  resetFileList,
  resetUploadState,
  setFileList,
  setUploadTableRows,
  updateUploadStatus,
  UploadStatusProps,
} from "../../../../store/reducers/upload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  setIsUploadModalShowing,
  setIsUploadPopupShowing,
} from "../../../../store/reducers/files";
import { ColumnProps } from "../../../atoms/tableHeader/TableHeader";
import { Modal, BaseTable, TextWithIcon } from "../../..";
import { getFileIcon } from "../../../../utils/file";
import { formatBytes } from "../../../../utils/formatNumber";
import { Button } from "../../../atoms/button/Button";
import {
  cancelFileUpload,
  CancelFileUploadFilters,
  createObject,
  replaceAcpMultipleFiles,
} from "../../../../services/apis";
import {
  parseFile,
  uploadFirstPart,
  uploadOtherParts,
} from "../../../../utils/upload";
import { PermissionSection } from "../../../molecules/fileList/permissionSection/PermissionSection";

interface ModalUpload {
  onSuccess: () => void;
}

export const UploadModal = (props: ModalUpload) => {
  const { onSuccess } = props;
  const {
    cancelCount,
    errorCount,
    fileList,
    successCount,
    uploadStatus,
    uploadTableRows,
  } = useAppSelector((state) => state.upload);
  const {
    bucketName,
    requestUrl,
    isUploadModalShowing,
    isUploadPopupShowing,
  } = useAppSelector((state) => state.files);
  const { currentSpace } = useAppSelector((state) => state.spaces);
  const dispatch = useAppDispatch();

  // Local file list will stay even when the modal closes
  const [localFileList, setLocalFileList] = useState<Array<File>>([]);
  const [uploadPermission, setUploadPermission] = useState<
    "PUBLIC" | "PRIVATE"
  >("PRIVATE");

  const tableColumns: Array<ColumnProps> = [
    {
      title: "Name",
      size: "lg",
    },
    {
      title: "Size",
      size: "sm",
    },
    {
      title: "",
      size: "xs",
    },
  ];

  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    dispatch(appendFileList(acceptedFiles));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onError = (error: Error, file: File) => {
    const errorMessage = error.message;
    dispatch(increaseUploadErrorCount());
    dispatch(
      updateUploadStatus({
        filename: file.name,
        payload: {
          status: "error",
          errorMessage: errorMessage,
        },
      })
    );
  };

  const onConfirmUpload = async () => {
    onCloseModal();
    dispatch(setIsUploadPopupShowing(true));
    setLocalFileList(fileList);

    await Promise.allSettled(
      fileList.map(async (file: File) => {
        const reader = new FileReader();

        let newFile = file;
        let existingCount = 0;

        uploadStatus.forEach((u) => {
          if (u.originalFilename === file.name) {
            existingCount += 1;
          }
        });

        if (existingCount > 0) {
          const nameArray = file.name.split(".");
          nameArray[0] = `${nameArray[0]}(${existingCount})`;
          const newFilename = nameArray.join(".");
          newFile = new File([newFile], newFilename, { type: newFile.type });
        }

        const uploadPath = `${requestUrl}${newFile.name}`;

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = async () => {
          const binaryStr = reader.result;

          const filter = {
            path: uploadPath,
          };

          const payload = {
            spaceKey: currentSpace,
            bucketName,
            file: binaryStr,
          };

          const uploadInfo: UploadStatusProps = {
            errorMessage: "",
            cancelTokenSource: null,
            file: newFile,
            originalFilename: file.name,
            status: "uploading",
            uploadPath,
            uploadProgress: 0,
          };

          dispatch(appendUploadStatus(uploadInfo));

          try {
            const ourRequest = axios.CancelToken.source();
            const axiosConfig = {
              cancelToken: ourRequest.token,
              headers: {
                "Content-Type": newFile.type,
                // "Content-Type": "application/octet-stream",
              },
              onUploadProgress: (progressEvent: ProgressEvent) => {
                const uploadProgress =
                  (progressEvent.loaded / progressEvent.total) * 100;
                dispatch(
                  updateUploadStatus({
                    filename: newFile.name,
                    payload: { uploadProgress },
                  })
                );
              },
            };

            dispatch(
              updateUploadStatus({
                filename: newFile.name,
                payload: { cancelTokenSource: ourRequest },
              })
            );
            const response = await createObject(payload, filter, axiosConfig);

            if (response.status === 201) {
              dispatch(increaseUploadSuccessCount());
              dispatch(
                updateUploadStatus({
                  filename: newFile.name,
                  payload: { status: "success" },
                })
              );
            }
          } catch (error) {
            dispatch(appendFailedList(newFile));

            if (axios.isCancel(error)) {
              dispatch(increaseCancelCount());
              dispatch(
                updateUploadStatus({
                  filename: newFile.name,
                  payload: { status: "cancel" },
                })
              );
            } else {
              const e = error as AxiosError;
              const errorData = e.response?.data;
              const errorCode = errorData && typeof errorData === 'object' && 'errorCode' in errorData ? errorData.errorCode : '';
              dispatch(increaseUploadErrorCount());
              dispatch(
                updateUploadStatus({
                  filename: newFile.name,
                  payload: {
                    status: "error",
                    errorMessage: errorCode as string,
                  },
                })
              );
            }
          }
        };

        const maxFileSize = 5 * 1024 * 1024;

        if (newFile.size > maxFileSize) {
          const total = Math.round(newFile.size / maxFileSize);
          // let chunks: Array<ArrayBuffer | string> = [];
          let finished = 0;
          let uploadInfo: UploadStatusProps = {
            errorMessage: "",
            cancelTokenSource: null,
            currentChunk: 0,
            file: newFile,
            originalFilename: file.name,
            status: "uploading",
            uploadPath,
            uploadProgress: 0,
          };

          parseFile(newFile, {
            binary: true,
            chunk_size: 5 * 1024 * 1024,
            // chunk_read_callback: (result) => {
            //   chunks.push(result);
            // },
            chunk_read_callback: async (chunk, partNo) => {
              try {
                if (partNo === 1) {
                  dispatch(appendUploadStatus(uploadInfo));
                  // First Chunk
                  await uploadFirstPart({
                    spaceKey: currentSpace,
                    bucketName,
                    chunk,
                    fileType: newFile.type,
                    uploadPath,
                    onStart: (cancelTokenSource) => {
                      dispatch(
                        updateUploadStatus({
                          filename: newFile.name,
                          payload: { cancelTokenSource },
                        })
                      );
                    },
                    updateUploadStatus: (status) => {
                      finished += 1;
                      const uploadProgress = (finished / total) * 100;
                      uploadInfo = {
                        ...uploadInfo,
                        ...status,
                        uploadProgress,
                      };
                      dispatch(
                        updateUploadStatus({
                          filename: newFile.name,
                          payload: uploadInfo,
                        })
                      );
                    },
                  });
                } else {
                  // Middle Chunks
                  const handleMiddleUpload = async () => {
                    if (!uploadInfo || !uploadInfo.uploadId) {
                      const timer = setTimeout(() => {
                        handleMiddleUpload();
                        clearTimeout(timer);
                      }, 2500);
                    } else {
                      await uploadOtherParts({
                        spaceKey: currentSpace,
                        bucketName,
                        chunk,
                        currentChunk: partNo,
                        fileType: newFile.type,
                        isFinalChunk: 0,
                        uploadInfo,
                        onStart: (cancelTokenSource) => {
                          dispatch(
                            appendCancelTokenSourceList({
                              filename: newFile.name,
                              payload: cancelTokenSource,
                            })
                          );
                        },
                        updateUploadStatus: () => {
                          finished += 1;
                          const uploadProgress = (finished / total) * 100;
                          dispatch(
                            updateUploadStatus({
                              filename: newFile.name,
                              payload: { uploadProgress },
                            })
                          );
                        },
                      });
                    }
                  };
                  await handleMiddleUpload();
                }
              } catch (error) {
                dispatch(appendFailedList(newFile));

                if (axios.isCancel(error)) {
                  if (uploadInfo) {
                    uploadInfo.status = "cancel";
                  }
                  dispatch(increaseCancelCount());
                  dispatch(
                    updateUploadStatus({
                      filename: newFile.name,
                      payload: { status: "cancel" },
                    })
                  );
                } else {
                  if (uploadInfo && uploadInfo.status !== "cancel") {
                    onError(error as AxiosError, newFile);
                    dispatch(increaseUploadErrorCount());
                  }
                }

                if (uploadInfo && uploadInfo.uploadId) {
                  const cancelFilter: CancelFileUploadFilters = {
                    path: uploadPath,
                  };
                  await cancelFileUpload(
                    currentSpace,
                    bucketName,
                    uploadInfo.uploadId,
                    cancelFilter
                  );
                }

                return; // Stop the loop if error
              }
            },
            success: async (chunk, partNo) => {
              if (errorCount) {
                return;
              }

              const handleLastUpload = async () => {
                if (finished + 1 < total) {
                  const timer = setTimeout(() => {
                    handleLastUpload();
                    clearTimeout(timer);
                  }, 4500);
                } else {
                  try {
                    // Last Chunk
                    await uploadOtherParts({
                      spaceKey: currentSpace,
                      bucketName,
                      chunk,
                      currentChunk: partNo,
                      fileType: newFile.type,
                      isFinalChunk: 1,
                      uploadInfo,
                      onStart: (cancelTokenSource) => {
                        dispatch(
                          appendCancelTokenSourceList({
                            filename: newFile.name,
                            payload: cancelTokenSource,
                          })
                        );
                      },
                      updateUploadStatus: () => {
                        dispatch(increaseUploadSuccessCount());
                        dispatch(
                          updateUploadStatus({
                            filename: newFile.name,
                            payload: {
                              status: "success",
                              uploadProgress: 100,
                            },
                          })
                        );
                      },
                    });
                  } catch (error) {
                    dispatch(appendFailedList(file));

                    if (axios.isCancel(error)) {
                      dispatch(increaseCancelCount());
                      dispatch(
                        updateUploadStatus({
                          filename: newFile.name,
                          payload: { status: "cancel" },
                        })
                      );
                    } else {
                      onError(error as AxiosError, newFile);
                      dispatch(increaseUploadErrorCount());
                    }

                    if (uploadInfo.uploadId) {
                      const cancelFilter: CancelFileUploadFilters = {
                        path: uploadPath,
                      };
                      await cancelFileUpload(
                        currentSpace,
                        bucketName,
                        uploadInfo.uploadId,
                        cancelFilter
                      );
                    }

                    return; // Stop the loop if error
                  }
                }
              };
              await handleLastUpload();
            },
            error_callback: (error) => {
              onError(error as Error, file);
              return;
            },
          });
        } else {
          reader.readAsArrayBuffer(file);
        }
      })
    );
  };

  const onClickDelete = (file: File) => {
    const filtered = fileList.filter((f: File) => f.name !== file.name);
    dispatch(setFileList(filtered));
  };

  const onPermissionSelect = (value: string) => {
    setUploadPermission(value as "PUBLIC" | "PRIVATE");
  };

  useEffect(() => {
    if (!isUploadPopupShowing) {
      dispatch(resetUploadState());
      return;
    }

    const setPermissions = async () => {
      const permissionPayload = {
        // fileList has already been reset when it reaches this line. So, use the local one instead.
        targetPaths: localFileList.map(
          (file: File) => `${requestUrl}${file.name}`
        ),
        grants: [
          {
            granteeId: "EVERYONE",
            permission: uploadPermission,
          },
        ],
      };

      try {
        await replaceAcpMultipleFiles(
          currentSpace,
          bucketName,
          permissionPayload
        );
        setLocalFileList([]);
        setUploadPermission("PRIVATE");
      } catch (error) {
        console.error(error);
      }
    };

    if (successCount + errorCount + cancelCount === uploadStatus.length) {
      setPermissions();
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCount, errorCount]);

  const onCloseModal = () => {
    document.body.style.overflow = "auto";
    dispatch(setIsUploadModalShowing(false));
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      onCloseModal();
    }
  };

  useEffect(() => {
    if (!fileList) {
      return;
    }

    const rows = fileList.map((file: File) => {
      const extension = file.name.split(".").pop();

      return {
        columns: [
          <TextWithIcon
            icon={getFileIcon(extension as string)}
            text={file.name}
          />,
          formatBytes(file.size),
          <ActionButton onClick={() => onClickDelete(file)}>
            <FontAwesomeIcon icon={["fas", "trash-alt"]} />
          </ActionButton>,
        ],
      };
    });
    dispatch(setUploadTableRows(rows));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  useEffect(() => {
    if (!isUploadModalShowing) {
      dispatch(resetFileList());
      window.removeEventListener('keydown', handleKeyDown);

      if (localFileList.length === 0) {
        setUploadPermission("PRIVATE");
      }
    } else {
      window.addEventListener('keydown', handleKeyDown);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadModalShowing]);

  if (!isUploadModalShowing) {
    return <></>;
  }

  return (
    <UploadModalContainer>
      <Modal
        title={"Upload"}
        onClose={onCloseModal}
        isOpen={isUploadModalShowing}
      >
        <DropArea {...getRootProps()} hasFile={fileList.length > 0}>
          <input {...getInputProps()} />
          <FontAwesomeIcon size={"3x"} icon={["fas", "cloud-upload-alt"]} />
          {isDragActive ? (
            <p>Drop the files here...</p>
          ) : (
            <p>Click to choose files or drag them here</p>
          )}
        </DropArea>
        {uploadTableRows ? (
          <TableContainer>
            <BaseTable columns={tableColumns} rows={uploadTableRows} />
            <PermissionSection
              value={uploadPermission}
              onSelect={onPermissionSelect}
            />
            <UploadButtonContainer>
              <Button
                disable={fileList.length === 0}
                onClick={onConfirmUpload}
                fullWidth={true}
                buttonStyle={"primary"}
              >
                Upload {fileList.length} file{fileList.length > 1 ? "s" : ""}
              </Button>
            </UploadButtonContainer>
          </TableContainer>
        ) : (
          <></>
        )}
      </Modal>
    </UploadModalContainer>
  );
};
