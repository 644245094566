import _ from "lodash";
import { AxiosError } from "axios";
import { useState, useEffect, ChangeEvent, MouseEvent, useRef } from "react";
import { Button, Modal } from "../../..";
import { Checkbox } from "../../../atoms/checkbox/Checkbox";
import { InputTagLabelNewDesign } from "../../../atoms/inputTagLabel/InputTagLabelNewDesign";
import { NumberInput } from "../../../atoms/numberInput/NumberInput";
import { Tooltip } from "../../../atoms/tooltip/Tooltip";
import { NotificationDelete } from "../../../molecules/notificationDelete/NotificationDelete";
import { NotificationBar } from "../../../molecules/notificationBar/NotificationBar";
import { ColumnProps } from "../../../atoms/tableHeader/TableHeader";
import { BaseTable } from "../../../molecules/baseTable/BaseTable";
import {
  getBucketCorsPolicy,
  replaceBucketCorsPolicy,
  deleteBucketCorsPolicy,
  rulesetPayload,
} from "../../../../services/apis";

import { validateUrl } from "../../../../utils/validate";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";

import { LoadingPlaceholder } from "../../../atoms/loadingPlaceholder/LoadingPlaceholder";
import {
  setCorsErrorMessage,
  setIsCorsModalShowing,
} from "../../../../store/reducers/settings";

import {
  ModalContainer,
  ModalCorsConfigContainer,
  ModalFooterContainer,
  ErrorText,
  ButtonContainer,
  ModalContent,
  HeaderTable,
  TextOrigins,
  RowContainer,
  RulesContainer,
  RulesInfomation,
  RuleInfomationSection,
  BackToRuleContainer,
  BackIconArrow,
  BackText,
  OriginContainer,
  OriginsHolder,
  Origin,
  EditOrigin,
  EditOriginText,
  EditOriginIcon,
  AddRulesContainer,
  IconWarpper,
  AddRulesText,
  OriginArea,
  WarningOriginMessage,
  WarningIconWarpper,
  WarningInvalid,
  WarningEmpty,
  Header,
  Info,
  TableDescription,
  AllowedMethodContainer,
  CheckboxContainer,
  CheckboxItem,
  Label,
  MethodsHolder,
  AllowedHeaderContainer,
  AccessControlContainer,
  InputNumberWrapper,
  DiscardContainer,
  TitleNotificationBar,
  TextNotificationContainer,
  SaveContainer,
} from "./ModalCorsConfigNew.style";
import { v4 as uuid } from "uuid";
import {
  addToast,
  removeToast,
  ToastProps,
} from "../../../../store/reducers/toasts";

interface CorsErrorResponse extends Response {
  data: {
    errorCode: string;
  };
}

interface CorsError extends Error {
  response: CorsErrorResponse;
}

interface ModalCorsConfigProps {
  onSuccess: () => void;
}

export const ModalCorsConfigNew = (props: ModalCorsConfigProps) => {
  const dispatch = useAppDispatch();
  const { onSuccess } = props;

  const { corsErrorMessage, isCorsModalShowing } = useAppSelector(
    (state) => state.settings
  );
  const [isCorErrorMessage, setIsCorErrorMessage] = useState(false);

  const { bucketName } = useAppSelector((state) => state.files);
  const { currentSpace } = useAppSelector((state) => state.spaces);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowingInfomation, setIsShowingInfomation] = useState<boolean>(
    false
  );

  const [data, setData] = useState<Array<rulesetPayload>>([]);
  const [mockData, setMockData] = useState<Array<rulesetPayload>>([]);
  const [originsData, setOriginsData] = useState<Array<rulesetPayload>>([]);

  const [indexData, setIndexData] = useState<number>(-1);
  const [currentRuleIndex, setCurrentRuleIndex] = useState(0);

  const [notificationState, setNotificationState] = useState<
    "CloseModal" | "AddRule" | "ChangeRule" | "Back" | ""
  >("");

  const [isReadyToSave, setIsReadyToSave] = useState<boolean>(false);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [textAreaValue, setTextAreaValue] = useState("");

  const [isShowingDeleteWarning, setIsShowingDeleteWarning] = useState(false);

  const AllowedMethods = [
    { method: "GET", checked: true },
    { method: "PUT", checked: false },
    { method: "DELETE", checked: false },
    { method: "POST", checked: false },
    { method: "HEAD", checked: false },
  ];

  const columns: Array<ColumnProps> = [
    {
      title: (
        <TextOrigins>
          Origins
          <Tooltip
            position={"bottom"}
            textAlign={"left"}
            text={
              "Allowed Origins must be a complete domain including protocol identifier (http) or wildcard (*)."
            }
          >
            <FontAwesomeIcon icon={["fas", "info-circle"]} color="#9EA4A9" />
          </Tooltip>
        </TextOrigins>
      ),
      size: "md",
    },
  ];

  const putData = async (
    data: Array<rulesetPayload>,
    state: "CloseModal" | "AddRule" | "ChangeRule" | "Back" | ""
  ) => {
    const id = uuid();
    try {
      const response = await replaceBucketCorsPolicy({
        spaceKey: currentSpace,
        bucketName,
        data: { ruleset: checkSavedData(data) },
      });
      if (response.status === 200) {
        if (isShowingInfomation) {
          const newData = checkSavedData(data);
          setData(newData);
          setMockData(newData);
        }
        setNotificationState(state);
        onSuccess();
      }
      // Toast
      const toastData: ToastProps = {
        id,
        status: "success",
        title: "CORS Configuraitons Updated",
      };
      dispatch(addToast(toastData));

      let timer = setTimeout(() => {
        dispatch(removeToast(id));
        clearTimeout(timer);
      }, 5000);
    } catch (error) {
      const e = error as AxiosError;
      setIsCorErrorMessage(true);
      setNotificationState("");
      dispatch(setCorsErrorMessage(e.message));
      // Toast
      const toastData: ToastProps = {
        id,
        status: "error",
        title: "Update CORS Configurations Failed",
        description:
          "CORS Configurations couldn't be updated. Please try again.",
      };
      dispatch(addToast(toastData));

      let timer = setTimeout(() => {
        dispatch(removeToast(id));
        clearTimeout(timer);
      }, 5000);
    }
  };

  const onAddRules = () => {
    if (!isShowingInfomation) {
      addRules();
      setIsShowingInfomation(true);
    } else {
      if (
        _.isEqual(data[indexData], mockData[indexData]) &&
        data[data.length - 1]?.allow?.origins[0] !== ""
      ) {
        addRules();
        setIsShowingDeleteWarning(false);
      } else {
        setNotificationState("AddRule");
      }
    }
  };

  const addRules = (isDiscard?: boolean) => {
    const saveData = isDiscard
      ? checkSavedData(data)
      : checkSavedData(mockData);
    const newData = [
      ...saveData,
      {
        allow: {
          methods: ["GET"],
          origins: [""],
          headers: [],
        },
        exposeHeaders: [],
        maxAgeInSeconds: 0,
      },
    ];
    setData(newData);
    setMockData(newData);
    setIndexData(saveData.length);
  };

  const checkSavedData = (data: Array<rulesetPayload>) => {
    data.map((item) => {
      let newItem = item;
      let newOrigin = newItem.allow.origins.filter((origin) => {
        return origin.trim() !== "";
      });
      newItem.allow.origins = newOrigin;
      return newItem;
    });

    const newData = data.filter((item) => item.allow.origins.length !== 0);
    return newData;
  };

  const resetData = () => {
    const newData = checkSavedData(data);
    setData(newData);
    setMockData(newData);
  };

  const resetState = (
    state: "CloseModal" | "AddRule" | "ChangeRule" | "Back" | "",
    isDiscard?: boolean
  ) => {
    switch (state) {
      case "CloseModal":
        dispatch(setIsCorsModalShowing(false));
        setIsShowingInfomation(false);
        setIsShowingDeleteWarning(false);
        setIndexData(-1);
        break;
      case "AddRule":
        addRules(isDiscard);
        break;
      case "ChangeRule":
        if (isDiscard) {
          resetData();
        }
        setIndexData(currentRuleIndex);
        setIsShowingDeleteWarning(false);
        break;
      case "Back":
        setIsShowingInfomation(false);
        setIsShowingDeleteWarning(false);
        setIndexData(-1);
        break;
      default:
        break;
    }
    setNotificationState("");
  };

  const onSave = (
    state: "CloseModal" | "AddRule" | "ChangeRule" | "Back" | ""
  ) => {
    let cloneData = _.cloneDeep(mockData);
    cloneData.forEach((d, index) => {
      cloneData[index] = {
        allow: {
          ...d.allow,
          headers: d.allow.headers.filter((e) => e),
        },
        exposeHeaders: d.exposeHeaders,
        maxAgeInSeconds: d.maxAgeInSeconds,
      };
    });

    const saveData = async (data: Array<rulesetPayload>) => {
      await putData(data, state);
      resetState(state);
    };

    saveData(cloneData);
  };

  const onDiscard = (
    state: "CloseModal" | "AddRule" | "ChangeRule" | "Back" | ""
  ) => {
    let preparedData = _.cloneDeep(data);
    setMockData(preparedData);
    resetState(state, true);
    setNotificationState("");
    setIsCorErrorMessage(false);
  };

  const onShowingInfomation = (index: number) => {
    setIsCorErrorMessage(false);
    if (!isShowingInfomation) {
      setIsShowingInfomation(true);
      setIndexData(index);
      setMockData(data);
    } else {
      if (indexData !== index) {
        if (_.isEqual(data[indexData], mockData[indexData])) {
          resetData();
          setIsShowingInfomation(true);
          setIndexData(index);
          setIsShowingDeleteWarning(false);
        } else {
          setNotificationState("ChangeRule");
        }
      }
      setCurrentRuleIndex(index);
    }
  };

  const onClickBack = () => {
    if (_.isEqual(data[indexData], mockData[indexData])) {
      resetData();
      resetState("Back");
    } else {
      setNotificationState("Back");
    }
  };

  const mapOrigin = (filtered?: Array<rulesetPayload>) => {
    const baseArray = filtered ? filtered : mockData;

    if (!baseArray.length) {
      return;
    }

    return baseArray.map((item, index) => {
      const origins = _.get(item.allow, "origins", null);

      return (
        <OriginContainer
          onClick={() => {
            onShowingInfomation(index);
          }}
          key={`origin-${index}`}
          isActive={index === indexData}
        >
          <Origin
            isNewRule={
              item && origins?.length && origins[0] !== "" ? false : true
            }
            isShowingInfomation={isShowingInfomation}
          >
            {origins && origins?.length && origins[0] !== ""
              ? origins.join(", ")
              : "http://example.com"}
          </Origin>
          <EditOrigin>
            <EditOriginText isShowingInfomation={isShowingInfomation}>
              edit
            </EditOriginText>
            <EditOriginIcon>
              <FontAwesomeIcon icon={["fas", "angle-right"]} />
            </EditOriginIcon>
          </EditOrigin>
        </OriginContainer>
      );
    });
  };

  const mapOriginInfomation = (
    index: number,
    filtered?: Array<rulesetPayload>
  ) => {
    const baseArray = filtered ? filtered : mockData;
    const allowedHeaderItem = _.get(baseArray[index], "allow.headers");
    const allowedMethods = _.get(baseArray[index], "allow.methods");
    const allowedOrigins = _.get(baseArray[index], "allow.origins");

    if (!baseArray.length) {
      return;
    }

    return (
      <RulesInfomation isShowingInfomation={isShowingInfomation}>
        <BackToRuleContainer onClick={onClickBack}>
          <BackIconArrow>
            <FontAwesomeIcon
              color={"var(--primary)"}
              icon={["fas", "chevron-left"]}
            />
          </BackIconArrow>
          <BackText>Back</BackText>
        </BackToRuleContainer>
        <RuleInfomationSection>
          <OriginsHolder>
            <Header>Origins</Header>
            <Info>
              Enter the origins that you want to allow cross-domain requests
              from, one per line
            </Info>

            <OriginArea
              value={allowedOrigins ? allowedOrigins.join(`\n`) : ""}
              onChange={(event) => onChangeOrigin(event, index)}
              ref={textAreaRef}
              placeholder={
                allowedOrigins && allowedOrigins.length
                  ? ""
                  : "http://example.com"
              }
              isInvalid={!validateOrigin(allowedOrigins)}
            ></OriginArea>

            {(!validateOrigin(allowedOrigins) ||
              validateIsOriginsEmpty(allowedOrigins)) && (
              <WarningOriginMessage>
                <WarningIconWarpper>
                  <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />{" "}
                </WarningIconWarpper>
                {!validateOrigin(allowedOrigins) &&
                  !validateIsOriginsEmpty(allowedOrigins) && (
                    <WarningInvalid>
                      Invalid origin. Origin must include http or https and a
                      valid domain.
                    </WarningInvalid>
                  )}
                {validateIsOriginsEmpty(allowedOrigins) && (
                  <WarningEmpty>This field can’t be empty.</WarningEmpty>
                )}
              </WarningOriginMessage>
            )}
          </OriginsHolder>
        </RuleInfomationSection>
        <RuleInfomationSection>
          <MethodsHolder>
            <Header>Allowed Methods</Header>
            <Info>
              Whitelist of methods that are allowed when accessing a resource in
              response to a preflight request.
            </Info>
            <TableDescription>
              <AllowedMethodContainer>
                <CheckboxContainer>
                  {AllowedMethods.map((prop, methodIndex) => (
                    <CheckboxItem key={`allow-method-${methodIndex}`}>
                      <Checkbox
                        label={prop.method}
                        checked={
                          Boolean(allowedMethods &&
                          allowedMethods.length &&
                          allowedMethods.includes(prop.method))
                        }
                        onClick={(e) => onCheck(e, prop.method, index)}
                      />
                    </CheckboxItem>
                  ))}
                </CheckboxContainer>

                {!Boolean(allowedMethods && allowedMethods.length) && (
                  <Label>
                    <span>
                      <FontAwesomeIcon icon={["fas", "exclamation-triangle"]} />{" "}
                    </span>
                    Please select at least 1 method.
                  </Label>
                )}
              </AllowedMethodContainer>
            </TableDescription>
          </MethodsHolder>
        </RuleInfomationSection>
        <RuleInfomationSection>
          <AllowedHeaderContainer>
            <Header>Allowed Headers (optional)</Header>
            <Info>
              Whitelist of headers that are allowed in a preflight request.
            </Info>
            <InputTagLabelNewDesign
              placeHolder="Custom-Header"
              addingText="Add header"
              validateErrorInput={validateHeader}
              label="Invalid header. Header can't be * and empty."
              tags={allowedHeaderItem && allowedHeaderItem}
              onChange={(event, indexToEdit) =>
                onChangeAllowedHeader(event, indexData, indexToEdit)
              }
              onClick={() => onAddAllowedHeader(indexData)}
              onDeleteTag={(indexToRemove) =>
                onDeleteAllowedHeader(indexToRemove, indexData)
              }
            />
          </AllowedHeaderContainer>
        </RuleInfomationSection>
        <RuleInfomationSection>
          <AccessControlContainer>
            <div>
              <Header>Access Control Max Age (optional)</Header>
              <Info>Value in seconds to cache preflight request results.</Info>
              <InputNumberWrapper>
                <NumberInput
                  value={baseArray[index]?.maxAgeInSeconds}
                  placeHolder="0"
                  unit="seconds"
                  onChange={(event) => onInputNumber(event, index)}
                  label="Invalid value. Max age can't be negative value."
                />
              </InputNumberWrapper>
            </div>
          </AccessControlContainer>
        </RuleInfomationSection>

        {!isShowingDeleteWarning ? (
          <RuleInfomationSection>
            <Button
              buttonStyle="danger-outline"
              onClick={() => setIsShowingDeleteWarning(true)}
            >
              Delete Rule
            </Button>
          </RuleInfomationSection>
        ) : (
          <NotificationDelete
            title="Are you sure you want to delete this rule?"
            description="Once you confirm, this rule will be permanently deleted."
            isShowing={isShowingDeleteWarning}
            onConfirm={() => onDeleteRules(index)}
            onCancel={() => setIsShowingDeleteWarning(false)}
          />
        )}
      </RulesInfomation>
    );
  };

  const onChangeAllowedHeader = (
    event: ChangeEvent<HTMLInputElement>,
    index: number,
    indexToEdit: number
  ) => {
    let preparedData = _.cloneDeep(mockData);
    if (preparedData) {
      preparedData[index].allow.headers[indexToEdit] = event.target.value;
      setMockData(preparedData);
    }

    const origins = preparedData[index].allow.origins.filter((e) => e);
    const allowMethods = preparedData[index].allow.methods.filter((e) => e);

    if (
      validateHeaderArray(preparedData[index].allow.headers) &&
      data[index].allow.headers !== preparedData[index].allow.headers &&
      origins.length > 0 &&
      allowMethods.length > 0
    ) {
      setIsReadyToSave(true);
    } else {
      setIsReadyToSave(false);
    }
  };

  const onAddAllowedHeader = (index: number) => {
    let preparedData = _.cloneDeep(mockData);
    if (preparedData[index].allow.headers) {
      preparedData[index].allow.headers.push("");
    } else {
      preparedData[index].allow = {
        ...preparedData[index].allow,
        headers: [""],
      };
    }
    setMockData(preparedData);
  };

  const onDeleteAllowedHeader = (indexToRemove: number, index: number) => {
    let preparedData = _.cloneDeep(mockData);
    const dataObject = preparedData[index];
    const originalHeaders = dataObject.allow.headers;
    const preparedAllowedHeader = (dataObject.allow.headers = originalHeaders.filter(
      (header: any, loopIndex: number) => loopIndex !== indexToRemove
    ));
    preparedData[index].allow.headers = preparedAllowedHeader;
    setMockData(preparedData);
    const origins = preparedData[index].allow.origins.filter((e) => e);
    if (
      data[index].allow.headers !== preparedData[index].allow.headers &&
      origins.length > 0
    ) {
      setIsReadyToSave(true);
    } else {
      setIsReadyToSave(false);
    }
  };

  const onClose = () => {
    if (_.isEqual(data[indexData], mockData[indexData])) {
      setIsCorErrorMessage(false);
      dispatch(setIsCorsModalShowing(false));
      setIsShowingInfomation(false);
      setIndexData(-1);
      resetData();
    } else {
      setNotificationState("CloseModal");
    }
  };

  const onCheck = (
    e: MouseEvent<HTMLElement>,
    method: string,
    index: number
  ) => {
    e.preventDefault();
    e.stopPropagation();

    let preparedData = _.cloneDeep(mockData);
    const hasMethod = preparedData[index].allow.methods.includes(method);

    if (hasMethod) {
      preparedData[index].allow.methods = preparedData[
        index
      ].allow.methods.filter((m: string) => m !== method);
    } else {
      preparedData[index].allow.methods.push(method);
    }

    preparedData[index].allow.methods.sort();
    setMockData(preparedData);

    const origins = preparedData[index].allow.origins.filter((e) => e);
    if (preparedData[index].allow.methods.length > 0 && origins.length > 0) {
      setIsReadyToSave(true);
    } else {
      setIsReadyToSave(false);
    }
  };

  const onInputNumber = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const number = parseInt(event.target.value, 10);
    let preparedData = _.cloneDeep(mockData);

    preparedData[index].maxAgeInSeconds = number;
    setMockData(preparedData);

    const origins = preparedData[index].allow.origins.filter((e) => e);
    const allowMethods = preparedData[index].allow.methods.filter((e) => e);

    if (
      data[index].maxAgeInSeconds !== preparedData[index].maxAgeInSeconds &&
      origins.length > 0 &&
      allowMethods.length > 0 &&
      number > 0
    ) {
      setIsReadyToSave(true);
    } else if (event.target.value === "") {
      setIsReadyToSave(true);
    } else {
      setIsReadyToSave(false);
    }
  };

  const onDeleteRules = async (index: number) => {
    const filteredData = data.filter((item, itemIndex) => itemIndex !== index);
    const newData = checkSavedData(filteredData);
    setData(newData);
    setMockData(newData);
    setIndexData(indexData === 0 ? 0 : indexData - 1);
    const id = uuid();

    try {
      let response;
      if (filteredData.length) {
        response = await replaceBucketCorsPolicy({
          spaceKey: currentSpace,
          bucketName,
          data: { ruleset: newData },
        });
      } else {
        response = await deleteBucketCorsPolicy(currentSpace, bucketName);
      }

      if (response.status === 200 || response.status === 204) {
        setNotificationState("");
        setIsShowingDeleteWarning(false);
        onSuccess();

        const toastData: ToastProps = {
          id,
          status: "success",
          title: "Rule Deleted",
        };
        dispatch(addToast(toastData));

        let timer = setTimeout(() => {
          dispatch(removeToast(id));
          clearTimeout(timer);
        }, 5000);
      }
    } catch (error) {
      const e = error as AxiosError;
      setNotificationState("");
      setIsCorErrorMessage(true);
      dispatch(setCorsErrorMessage(e.message));
      const toastData: ToastProps = {
        id,
        status: "error",
        title: "Delete Rule Failed",
        description: "Rule couldn't be deleted. Please try again.",
      };
      dispatch(addToast(toastData));

      let timer = setTimeout(() => {
        dispatch(removeToast(id));
        clearTimeout(timer);
      }, 5000);
    }
  };

  const onChangeOrigin = (
    event: ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    let preparedData = _.cloneDeep(mockData);
    const origins = event.target.value.split("\n");
    if (preparedData) {
      preparedData[index].allow.origins = origins;
      setMockData(preparedData);
      setOriginsData(preparedData);
    }

    const allOrigins = preparedData[index].allow.origins.filter((e) => e);
    const allowMethods = preparedData[index].allow.methods.filter((e) => e);

    if (
      validateOrigin(origins) &&
      allOrigins.length > 0 &&
      allowMethods.length > 0
    ) {
      setIsReadyToSave(true);
    } else {
      setIsReadyToSave(false);
    }

    setTextAreaValue(event.target.value);
  };

  const validateOrigin = (origins: Array<string> | null) => {
    if (!origins) {
      return false;
    }

    for (let item = 0; item < origins.length; item++) {
      if (!origins[item].length && origins.length <= 1) {
        return true;
      }
      if (!validateUrl(origins[item])) {
        return false;
      }
    }
    return true;
  };

  const validateIsOriginsEmpty = (origins: Array<string> | null) => {
    if (!origins) {
      return true;
    }

    let isEmpty = [];
    for (let item = 0; item < origins.length; item++) {
      if (origins[item].trim() === "" && origins.length > 1) {
        isEmpty.push(true);
      }
    }
    return isEmpty.length === origins.length;
  };

  const validateHeader = (headers: string): boolean => {
    const regex = new RegExp(/\*.*\*/);
    return regex.test(headers);
  };

  const validateHeaderArray = (headers: Array<string>) => {
    for (let item = 0; item < headers.length; item++) {
      if (validateHeader(headers[item])) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    if (
      originsData &&
      Boolean(originsData[indexData]) &&
      textAreaRef &&
      textAreaRef.current
    ) {
      textAreaRef.current.style.height = "0px";
      const scrollHeight = textAreaRef.current.scrollHeight;
      if (originsData[indexData].allow.origins.length >= 2) {
        textAreaRef.current.style.height = scrollHeight + "px";
      } else {
        textAreaRef.current.style.height = scrollHeight + 23 + "px";
      }
    }
  }, [textAreaValue, isShowingInfomation, indexData, originsData]);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (bucketName.length) {
          const responseCors = await getBucketCorsPolicy(
            currentSpace,
            bucketName
          );
          const dataCors: rulesetPayload[] = _.get(
            responseCors,
            "data.ruleset"
          );
          // Sorted allow method.
          const sortedData = _.cloneDeep(dataCors);
          sortedData.map((item) => {
            let newItem = item;
            newItem.allow.methods.sort();
            return newItem;
          });

          // Add white space.
          const dataCorsWithSpace = _.cloneDeep(sortedData);
          dataCorsWithSpace.map((item) => {
            let newItem = item;
            if (item.allow.origins[item.allow.origins.length - 1] !== "") {
              newItem.allow.origins.push("");
            }
            newItem.allow.methods.sort();
            return newItem;
          });
          setData(sortedData);
          setMockData(dataCorsWithSpace);
          setOriginsData(dataCorsWithSpace);
          mapOrigin(sortedData);
          setIsLoading(false);
        }
      } catch (error) {
        setIsCorErrorMessage(true);
        setIsLoading(false);
        const e = error as CorsError;
        if (e.response) {
          const { status } = e.response;
          const { errorCode } = e.response.data;

          if (
            status === 404 ||
            (errorCode && errorCode === "noCorsConfigured")
          ) {
            setIsLoading(false);
          }
        }
      }
    };

    if (isCorsModalShowing) {
      loadData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCorsModalShowing]);

  return (
    <ModalContainer>
      <Modal
        isOpen={isCorsModalShowing}
        title="CORS Configurations"
        onClose={onClose}
      >
        <ModalCorsConfigContainer>
          <ModalContent>
            {Boolean(isLoading) ? (
              <>
                <HeaderTable isShowingInfomation={isShowingInfomation}>
                  <BaseTable columns={columns} rows={[]} isAlwaysShowHeader />
                </HeaderTable>
                <LoadingPlaceholder width={"100%"} height={"100px"} />
              </>
            ) : (
              <>
                <HeaderTable isShowingInfomation={isShowingInfomation}>
                  <BaseTable columns={columns} rows={[]} isAlwaysShowHeader />
                </HeaderTable>
                <RowContainer isShowingInfomation={isShowingInfomation}>
                  <RulesContainer isShowingInfomation={isShowingInfomation}>
                    {mapOrigin(data)}
                    <AddRulesContainer onClick={onAddRules}>
                      <IconWarpper color="#6C757D">
                        <FontAwesomeIcon icon={["fas", "plus"]} />
                      </IconWarpper>
                      <AddRulesText>Add rule</AddRulesText>
                    </AddRulesContainer>
                  </RulesContainer>
                  {indexData >= 0 && mapOriginInfomation(indexData, mockData)}
                </RowContainer>
              </>
            )}
          </ModalContent>
          <ModalFooterContainer>
            {isCorErrorMessage && <ErrorText>{corsErrorMessage}</ErrorText>}
            <SaveContainer>
              {data && mockData && notificationState === "" ? (
                <ButtonContainer>
                  <Button
                    fullWidth
                    disable={
                      _.isEqual(data[indexData], mockData[indexData]) ||
                      !validateOrigin(mockData[indexData].allow.origins) ||
                      !isReadyToSave
                    }
                    onClick={() => onSave("")}
                  >
                    Save
                  </Button>
                </ButtonContainer>
              ) : (
                <DiscardContainer isCorErrorMessage={isCorErrorMessage}>
                  <NotificationBar
                    backgroundColor="rgba(240, 181, 66, 0.05)"
                    borderColor="rgba(240, 181, 66, 0.5)"
                    isShowing
                    onSave={() => onSave(notificationState)}
                    onDiscard={() => onDiscard(notificationState)}
                    disable={
                      _.isEqual(data[indexData], mockData[indexData]) ||
                      !validateOrigin(mockData[indexData].allow.origins) ||
                      !isReadyToSave
                    }
                  >
                    <TextNotificationContainer>
                      <FontAwesomeIcon
                        icon={["fas", "exclamation-triangle"]}
                        color="#F0B542"
                      />
                      <TitleNotificationBar>
                        Careful - You have unsaved changes!
                      </TitleNotificationBar>
                    </TextNotificationContainer>
                  </NotificationBar>
                </DiscardContainer>
              )}
            </SaveContainer>
          </ModalFooterContainer>
        </ModalCorsConfigContainer>
      </Modal>
    </ModalContainer>
  );
};
