import byteSize from "byte-size";

export const formatNumber = (number: number) => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatBytes = (bytes: number) => {
  const { value, unit } = byteSize(bytes);
  if (isNaN(parseInt(value, 10))) {
    return `0 B`;
  }
  return `${value} ${unit.toUpperCase()}`;
};

export const formatBytes3Precision = (bytes: number) => {
  const { value, unit } = byteSize(bytes, {precision: 3});
  if (isNaN(parseInt(value, 10)) || value === "0") {
    return `0.000 B`;
  }
  return `${value} ${unit.toUpperCase()}`;
};
