import { BaseTable } from "../../../molecules/baseTable/BaseTable";
import {
  dismissPopover,
  setCurrentFilePath,
  setDeleteTarget,
  setTableRows,
  showDeleteModal,
  showPermissionModal,
  showPopover,
  addSelectTarget,
  removeSelectTarget,
  setDownloadError,
  setIsMetadataModalShowing,
} from "../../../../store/reducers/files";
import { TextWithIcon } from "../../../molecules/textWithIcon/TextWithIcon";
import { getFileIcon, onDownloadFile } from "../../../../utils/file";
import { formatDateTime } from "../../../../utils/dateTime";
import { formatBytes } from "../../../../utils/formatNumber";
import { TableActionButton } from "../../../../pages/fileList/FileList.style";
import { MouseEvent, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "../../../atoms/popover/Popover";
import { Checkbox } from "../../..";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  addToast,
  removeToast,
  ToastProps,
} from "../../../../store/reducers/toasts";
import { v4 as uuid } from "uuid";

interface DataProps {
  etag: string;
  extension: string;
  path: string;
  lastModified: string;
  name: string;
  size: number;
  type: string;
}

interface FileListTableProps {
  spaceKey: string;
}

export const FileListTable = (props: FileListTableProps) => {
  const { spaceKey } = props;
  const dispatch = useAppDispatch();
  const {
    bucketName,
    data,
    requestUrl,
    currentFilePath,
    popoverIndex,
    tableColumns,
    tableRows,
    selectTarget,
  } = useAppSelector((state) => state.files);

  const {
    successCount,
  } = useAppSelector((state) => state.upload);

  const { user } = useAppSelector((state) => state.user);

  const getRedirectUrl = (directory: string): string => {
    let link = `/${
      user?.team.namespace
    }/spaces/${spaceKey}/bucket/${bucketName}?path=${encodeURIComponent(
      requestUrl
    )}`;
    link += `${directory}%2F`;

    return link;
  };

  const getPopoverItems = (
    type: "folder" | "file",
    path: string,
    filename: string
  ) => {
    if (type === "folder") {
      return [];
    }

    return [
      {
        title: "Manage Permissions",
        onClick: () => {
          dispatch(showPermissionModal());
          dispatch(setDeleteTarget({ type, path }));
        },
      },
      {
        title: "Manage Metadata",
        onClick: () => {
          dispatch(setDeleteTarget({ type: "file", path }));
          dispatch(setIsMetadataModalShowing(true));
        },
      },
      {
        title: "Download",
        onClick: async () => {
          dispatch(setDownloadError(null));
          await onDownloadFile({
            spaceKey,
            bucketName,
            filename,
            path,
            onError: (error) => {
              dispatch(setDownloadError(error as Error));
              const id = uuid();
              const toastData: ToastProps = {
                id,
                status: "error",
                title: "Failed to download a file.",
                description: error.message,
              };
              dispatch(addToast(toastData));
              let timer = setTimeout(() => {
                dispatch(removeToast(id));
                clearTimeout(timer);
              }, 5000);
            },
          });

          dispatch(dismissPopover());
        },
      },
    ];
  };

  const getPopoverBottomAction = (type: "folder" | "file", path: string) => {
    return {
      title: "Delete",
      color: "#EB5757",
      onClick: () => {
        dispatch(setDeleteTarget({ type, path }));
        dispatch(showDeleteModal());
      },
    };
  };

  const onClickPopover = (
    e: MouseEvent<HTMLElement>,
    fileIndex: number,
    fileName: string
  ): void => {
    /* Prevent folder browsing */
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    dispatch(showPopover({ fileName, fileIndex }));
  };

  const onDismissPopover = (e: MouseEvent<HTMLElement>): void => {
    /* Prevent folder browsing */
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    dispatch(dismissPopover());
  };

  const onClickCheckbox = (
    e: MouseEvent<HTMLElement>,
    filePath: string,
    isFolder: boolean
  ): void => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (!isFolder) {
      if (selectTarget.includes(filePath)) {
        // Remove
        dispatch(removeSelectTarget(filePath));
      } else {
        // Add
        dispatch(addSelectTarget(filePath));
      }
    }
  };

  const mapTableRows = (): void => {
    if (!data) {
      return;
    }

    const rows = data.map((d: DataProps, index: number) => {
      let type = d.extension; // Trust extension first

      if (!type) {
        type = d.type;
      }

      const isFolder = type === "folder";

      const folders: Array<string> = requestUrl.split("/");
      folders.pop(); // Remove search term if existed
      const folderPath = folders.join("/");
      const filePath = `${folderPath}${folders.length > 0 ? "/" : ""}${d.name}`;

      const windowHeight = window.innerHeight || 720;
      let popoverPosition: "top" | "bottom" = "bottom";
      if (windowHeight > 768 && data.length > 7 && index + 4 > data.length) {
        popoverPosition = "top";
      } else if (
        windowHeight <= 768 &&
        data.length > 6 &&
        index + 4 > data.length
      ) {
        popoverPosition = "top";
      }

      return {
        redirectUrl: isFolder ? getRedirectUrl(d.name) : undefined,
        isRowActive: currentFilePath === filePath,
        onClick: !isFolder
          ? () => dispatch(setCurrentFilePath(filePath))
          : undefined,
        onClickPolicy: "secondColumnOnly",
        columns: [
          <Checkbox
            checked={!isFolder && selectTarget.includes(filePath)}
            disabled={isFolder}
            onClick={(e: MouseEvent<HTMLElement>) =>
              onClickCheckbox(e, filePath, isFolder)
            }
          />,
          <TextWithIcon icon={getFileIcon(type)} text={d.name} />,
          !isFolder ? formatDateTime(d.lastModified) : "-",
          !isFolder ? formatBytes(d.size) : "-",
          <>
            <TableActionButton
              onClick={(e: MouseEvent<HTMLElement>) =>
                onClickPopover(e, index, d.name)
              }
            >
              <FontAwesomeIcon color={"#6C757D"} icon={["fas", "ellipsis-h"]} />
            </TableActionButton>
            <Popover
              position={popoverPosition}
              isShowing={popoverIndex === index}
              items={getPopoverItems(
                isFolder ? "folder" : "file",
                filePath,
                d.name
              )}
              bottomAction={getPopoverBottomAction(
                isFolder ? "folder" : "file",
                filePath
              )}
              onDismiss={(e: MouseEvent<HTMLElement>) => onDismissPopover(e)}
            />
          </>,
        ],
      };
    });

    // @ts-ignore
    dispatch(setTableRows(rows));
  };

  useEffect(() => {
    mapTableRows();

    return () => {
      dispatch(setDownloadError(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, popoverIndex, currentFilePath, selectTarget, successCount]);

  if (!tableRows || !tableColumns) {
    return <></>;
  }

  return <BaseTable columns={tableColumns} rows={tableRows} />;
};
