import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { Button, Modal, TextInput } from "../../..";
import {
  fetchSpaces,
  resetErrorState,
  setIsSpacePermissionShowing,
} from "../../../../store/reducers/spaces";
import {
  ButtonContainer,
  ContentContainer,
  FormGroup,
  FormLabel,
  ChoiceContainer,
} from "./ModalManageSpacePermission.style";
import { Choice } from "../../../atoms/choice/Choice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { updateSpacePermission } from "../../../../services/apis";
import {
  addToast,
  removeToast,
  ToastProps,
} from "../../../../store/reducers/toasts";
import { AxiosError } from "axios";
import { v4 as uuid } from "uuid";

export const ModalManageSpacePermission = () => {
  const { isSpacePermissionShowing, selectedSpace } = useAppSelector(
    (state) => state.spaces
  );
  const [defaultValue, setDefaultValue] = useState<string>("");
  const [selected, setSelected] = useState<"owner-access" | "team-access">(
    "owner-access"
  );
  const [isClickedSave, setIsClickedSave] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    dispatch(setIsSpacePermissionShowing(false));
  };

  const onSave = async () => {
    if (!selectedSpace) {
      return;
    }

    setIsClickedSave(true);

    const id = uuid();

    try {
      await updateSpacePermission(selectedSpace.key, {
        defaultPermission: selected,
      });
      setIsClickedSave(false);
      setDefaultValue("");
      onCloseModal();

      const toastData: ToastProps = {
        id,
        status: "success",
        title: "Permission Updated",
        description: "The permissions have been updated.",
      };
      dispatch(addToast(toastData));
      dispatch(resetErrorState());
      await dispatch(fetchSpaces());
    } catch (error) {
      const e = error as AxiosError;
      setIsClickedSave(false);
      setDefaultValue("");
      onCloseModal();

      const errorData = e.response?.data;
      const errorCode = errorData && typeof errorData === 'object' && 'errorCode' in errorData ? errorData.errorCode : '';
      let errorMessage: string;

      switch (errorCode) {
        case "invalidRequest":
          errorMessage =
            "Permissions couldn’t be updated due to an invalid request. Please try again.";
          break;
        case "forbidden":
          errorMessage =
            "Permissions couldn’t be updated due to a permission issue. Please try again.";
          break;
        case "noSuchObject":
          errorMessage = "Space not found.";
          break;
        case "internalServerError":
          errorMessage =
            "There was an error while updating permission. Please try again.";
          break;
        default:
          errorMessage = `Permissions couldn’t be updated due to an unknown error. Please try again.`;
      }

      const toastData: ToastProps = {
        id,
        status: "error",
        title: "Update Permission Failed",
        description: errorMessage,
      };
      dispatch(addToast(toastData));
    }
    let timer = setTimeout(() => {
      dispatch(removeToast(id));
      clearTimeout(timer);
    }, 5000);
  };

  useEffect(() => {
    if (selectedSpace) {
      setSelected(selectedSpace.defaultPermission);
      setDefaultValue(selectedSpace.defaultPermission);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSpace]);

  if (!selectedSpace) {
    return <></>;
  }

  return (
    <Modal
      title={"Manage Permissions"}
      isOpen={isSpacePermissionShowing}
      onClose={onCloseModal}
    >
      <ContentContainer>
        <FormGroup>
          <FormLabel>Space Name</FormLabel>
          <TextInput text={selectedSpace.key} disabled={true} />
        </FormGroup>
        <FormGroup>
          <FormLabel>Permission</FormLabel>
          <ChoiceContainer>
            <Choice
              title={
                <>
                  <FontAwesomeIcon icon={["fas", "lock-alt"]} />Personal Access
                </>
              }
              description={
                "Only you can manage buckets and files in this Space."
              }
              isSelected={selected === "owner-access"}
              onClick={() => {
                setSelected("owner-access");
              }}
            />
          </ChoiceContainer>
          <ChoiceContainer>
            <Choice
              title={
                <>
                  <FontAwesomeIcon icon={["fas", "users"]} />Team Access
                </>
              }
              description={
                "Everyone in your team can manage buckets and files in this Space."
              }
              isSelected={selected === "team-access"}
              onClick={() => {
                setSelected("team-access");
              }}
            />
          </ChoiceContainer>
        </FormGroup>
        <ButtonContainer>
          <Button
            onClick={onSave}
            disable={isClickedSave || defaultValue === selected}
            buttonStyle={"primary"}
            fullWidth
          >
            Save
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Modal>
  );
};
