import styled, { css } from "styled-components";
import { maxLayout } from "../../../utils/breakpoint/breakpoint";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const SelectButton = styled.div<{ borderRadius?: string }>`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  padding: var(--space-s);
  color: var(--grey800);
  border: 1px solid var(--grey300);
  background-color: var(--grey300);
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "var(--border-radius)"};
  :hover {
    cursor: pointer;
  }
`;

export const SelectedValueLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectOverlay = styled.div<{ isActive?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: ${(props) => (props.isActive ? 12 : -10)};
`;

export const OptionsContainer = styled.div<{
  isActive?: boolean;
  isScrolling?: boolean;
}>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 13;
  border-radius: var(--border-radius-default);
  padding: 1px;
  width: 100%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  background: var(--white);
  border: 1px solid var(--grey300);
  ${(props) => {
    if (props.isActive) {
      return css`
        pointer-events: auto;
        opacity: 1;
        transform: translateY(4px);
      `;
    } else {
      return css`
        pointer-events: none;
        opacity: 0;
        transition: all 0.25s ease-out;
        transform: translateY(10px);
        visibility: hidden;
      `;
    }
  }}

  ${(props) =>
    props.isScrolling &&
    css`
      overflow-y: auto;
      max-height: 365px;

      @media ${maxLayout.sm} {
        max-height: 180px;
      }
    `}
`;

export const Option = styled.div<{ isSelected?: boolean }>`
  font-size: 12px;
  padding: var(--space-s);
  height: 30px;
  display: flex;
  align-items: center;
  color: ${(props) => (props.isSelected ? "var(--grey800)" : "var(--grey700)")};
  background: ${(props) =>
    props.isSelected ? "var(--grey300)" : "var(--white)"};
  :hover {
    background: var(--grey300);
    cursor: pointer;
  }
`;
