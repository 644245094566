import styled from "styled-components";

export const BreadcrumbContainer = styled.div`
  background-color: var(--grey100);
  border-radius: var(--border-radius-default) var(--border-radius-default) 0 0;
  border-bottom: 1px solid var(--grey400);
  padding-top: var(--space-s);
  padding-right: var(--space-base);
  padding-bottom: var(--space-s);
  padding-left: var(--space-base);
  font-size: 12px;
  
  .fa-chevron-right {
    width: 0.4em;
    margin-left: var(--space-s);
    margin-right: var(--space-s);
  }

  .fa-folder-open {
    font-size: 0.9em;
  }
`;

export const Path = styled.span<{ isActive: boolean}>`
  a {
    pointer-events: ${(props) => props.isActive ? 'none' : 'all'};
    :hover {
      text-decoration: underline;
      text-decoration-color: var(--grey700);
    }
  }
`;

export const PathSpan = styled.span<{ isActive: boolean}>`
  position: relative;
  color: ${(props) => props.isActive ? 'var(--grey900)' : 'var(--grey700)'};
  font-size: 12px;
  word-break: break-all;
`;
