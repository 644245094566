import styled, { css } from "styled-components";

export const Container = styled.div<{ withMargin: boolean }>`
  margin-bottom: ${(props) => props.withMargin ? "var(--space-base)" : "0"};
  width: 100%;
`;

export const Input = styled.input<{ error?: boolean }>`
  color: var(--grey700);
  background-color: var(--white);
  border: 1px solid var(--grey300);
  padding-top: var(--space-s);
  padding-right: var(--space-l);
  padding-bottom: var(--space-s);
  padding-left: var(--space-s);
  font-size: 14px;
  line-height: 1.4;
  width: 100%;
  border-radius: var(--border-radius-default);
  transition: border-color 0.2s ease-out;

  ::placeholder {
    color: var(--grey500);
  }

  &:focus,
  &:focus-visible {
    border-color: var(--primary);
    outline: none;
  }

  &:disabled {
    color: var(--grey700);
    background-color: var(--grey400);
  }

  ${(props) =>
    props.error &&
    css`
      border-color: var(--red) !important;
    `}
`;

export const Label = styled.p<{ error?: boolean }>`
  color: var(--grey700);
  font-size: 12px;
  margin-top: var(--space-xs);

  ${(props) =>
    props.error &&
    css`
      color: var(--red);
    `}
`;
